import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const blogService = {
  register,
  getAll,
  getById,
  getByYear,
  update,
  delete: _delete,
  subscribeEmail,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog/${id}`, requestOptions).then(handleResponse);
}

function getByYear(year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog/year/${year}`, requestOptions).then(handleResponse);
}

function register(blogpost) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(blogpost)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog`, requestOptions).then(handleResponse);
}

function update(blogpost) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(blogpost)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog/${blogpost.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog/${id}`, requestOptions).then(handleResponse);
}

function subscribeEmail(subscriber) {
  const requestOptions = {
    method: 'GET',
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/blog_subscribe/${subscriber.email}`, requestOptions).then(handleResponse);
}


