export const productConstants = {
  REGISTER_REQUEST: 'PRODUCT_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'PRODUCT_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'PRODUCT_REGISTER_FAILURE',

  GET_REQUEST: 'PRODUCT_REQUEST',
  GET_SUCCESS: 'PRODUCT_SUCCESS',
  GET_FAILURE: 'PRODUCT_FAILURE',

  GETALL_REQUEST: 'PRODUCT_GETALL_REQUEST',
  GETALL_SUCCESS: 'PRODUCT_GETALL_SUCCESS',
  GETALL_FAILURE: 'PRODUCT_GETALL_FAILURE',

  DELETE_REQUEST: 'PRODUCT_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',

  DOWNLOAD_REQUEST: 'PRODUCT_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'PRODUCT_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'PRODUCT_DOWNLOAD_FAILURE',

  CHECK_BOUGHT_REQUEST: 'CHECK_BOUGHT_REQUEST',
  CHECK_BOUGHT_SUCCESS: 'CHECK_BOUGHT_SUCCESS',
  CHECK_BOUGHT_FAILURE: 'CHECK_BOUGHT_FAILURE',

  SPOTLIGHT_SORT_REQUEST: 'SPOTLIGHT_SORT_REQUEST',
  SPOTLIGHT_SORT_SUCCESS: 'SPOTLIGHT_SORT_SUCCESS',
  SPOTLIGHT_SORT_FAILURE: 'SPOTLIGHT_SORT_FAILURE',
};