export const casesConstants = {
  REGISTER_REQUEST: 'CASES_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'CASES_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'CASES_REGISTER_FAILURE',

  GET_REQUEST: 'CASES_GET_REQUEST',
  GET_SUCCESS: 'CASES_GET_SUCCESS',
  GET_FAILURE: 'CASES_GET_FAILURE',

  GETALL_REQUEST: 'CASES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CASES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CASES_GETALL_FAILURE',

  DELETE_REQUEST: 'CASES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CASES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CASES_DELETE_FAILURE',

  GETALL_SECTIONS_REQUEST: 'GETALL_SECTIONS_REQUEST',
  GETALL_SECTIONS_SUCCESS: 'GETALL_SECTIONS_SUCCESS',
  GETALL_SECTIONS_FAILURE: 'GETALL_SECTIONS_FAILURE',

  GETALL_YEARS_REQUEST: 'YEARS_GETALL_REQUEST',
  GETALL_YEARS_SUCCESS: 'YEARS_GETALL_SUCCESS',
  GETALL_YEARS_FAILURE: 'YEARS_GETALL_FAILURE',

  IMPORT_REQUEST: 'CASES_IMPORT_REQUEST',
  IMPORT_SUCCESS: 'CASES_IMPORT_SUCCESS',
  IMPORT_FAILURE: 'CASES_IMPORT_FAILURE',
};