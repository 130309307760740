export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  ACTIVATE_REQUEST: 'USERS_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'USERS_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'USERS_ACTIVATE_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GET_PROFILE_REQUEST: 'USER_GET_REQUEST',
  GET_PROFILE_SUCCESS: 'USER_GET_SUCCESS',
  GET_PROFILE_FAILURE: 'USER_GET_FAILURE',

  UPDATE_PROFILE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'USER_UPDATE_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  RESET_REQUEST_REQUEST: 'RESET_REQUEST_REQUEST',
  RESET_REQUEST_SUCCESS: 'RESET_REQUEST_SUCCESS',
  RESET_REQUEST_FAILURE: 'RESET_REQUEST_FAILURE',

  RESET_VERIFY_REQUEST: 'RESET_VERIFY_REQUEST',
  RESET_VERIFY_SUCCESS: 'RESET_VERIFY_SUCCESS',
  RESET_VERIFY_FAILURE: 'RESET_VERIFY_FAILURE',

  RESET_CHANGE_REQUEST: 'RESET_CHANGE_REQUEST',
  RESET_CHANGE_SUCCESS: 'RESET_CHANGE_SUCCESS',
  RESET_CHANGE_FAILURE: 'RESET_CHANGE_FAILURE',

  MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

  ROLES_GETALL_REQUEST: 'ROLES_GETALL_REQUEST',
  ROLES_GETALL_SUCCESS: 'ROLES_GETALL_SUCCESS',
  ROLES_GETALL_FAILURE: 'ROLES_GETALL_FAILURE',

  AUTHORS_GETALL_REQUEST: 'AUTHORS_GETALL_REQUEST',
  AUTHORS_GETALL_SUCCESS: 'AUTHORS_GETALL_SUCCESS',
  AUTHORS_GETALL_FAILURE: 'AUTHORS_GETALL_FAILURE',

  ADDRESSES_GETALL_REQUEST: 'ADDRESSES_GETALL_REQUEST',
  ADDRESSES_GETALL_SUCCESS: 'ADDRESSES_GETALL_SUCCESS',
  ADDRESSES_GETALL_FAILURE: 'ADDRESSES_GETALL_FAILURE',

  PURCHASES_GETALL_REQUEST: 'PURCHASES_GETALL_REQUEST',
  PURCHASES_GETALL_SUCCESS: 'PURCHASES_GETALL_SUCCESS',
  PURCHASES_GETALL_FAILURE: 'PURCHASES_GETALL_FAILURE',

  COURSES_GETALL_REQUEST: 'COURSES_GETALL_REQUEST',
  COURSES_GETALL_SUCCESS: 'COURSES_GETALL_SUCCESS',
  COURSES_GETALL_FAILURE: 'COURSES_GETALL_FAILURE',

  BOOKS_GETALL_REQUEST: 'BOOKS_GETALL_REQUEST',
  BOOKS_GETALL_SUCCESS: 'BOOKS_GETALL_SUCCESS',
  BOOKS_GETALL_FAILURE: 'BOOKS_GETALL_FAILURE',

  QUESTIONS_GETALL_REQUEST: 'QUESTIONS_GETALL_REQUEST',
  QUESTIONS_GETALL_SUCCESS: 'QUESTIONS_GETALL_SUCCESS',
  QUESTIONS_GETALL_FAILURE: 'QUESTIONS_GETALL_FAILURE',

  ANSWERS_GETALL_REQUEST: 'ANSWERS_GETALL_REQUEST',
  ANSWERS_GETALL_SUCCESS: 'ANSWERS_GETALL_SUCCESS',
  ANSWERS_GETALL_FAILURE: 'ANSWERS_GETALL_FAILURE',

  SHOPPING_CART_GET_REQUEST: 'SHOPPING_CART_GET_REQUEST',
  SHOPPING_CART_GET_SUCCESS: 'SHOPPING_CART_GET_SUCCESS',
  SHOPPING_CART_GET_FAILURE: 'SHOPPING_CART_GET_FAILURE',
};
