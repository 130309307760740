import { briefingsConstants } from '../_constants';

export function briefings(state = {}, action) {
  switch (action.type) {
    case briefingsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case briefingsConstants.GETALL_SUCCESS:
      return {
        items: action.briefings
      };
    case briefingsConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function briefing(state = {}, action) {
  switch (action.type) {
    case briefingsConstants.REGISTER_SUCCESS:
      return {
        item: action.briefing.data
      };
    case briefingsConstants.GET_REQUEST:
      return {
        loading: true
      };
    case briefingsConstants.GET_SUCCESS:
      return {
        item: action.briefing.data
      };
    case briefingsConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case briefingsConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case briefingsConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case briefingsConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}
