import { course_moduleConstants } from '../_constants';

export function course_modules(state = {}, action) {
  switch (action.type) {
    case course_moduleConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case course_moduleConstants.GETALL_SUCCESS:
      return {
        items: action.course_modules.data
      };
    case course_moduleConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function course_module(state = {}, action) {
  switch (action.type) {
    case course_moduleConstants.REGISTER_SUCCESS:
      return {
        item: action.course_module.data
      };
    case course_moduleConstants.GET_REQUEST:
      return {
        loading: true
      };
    case course_moduleConstants.GET_SUCCESS:
      return {
        item: action.course_module.data
      };
    case course_moduleConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case course_moduleConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case course_moduleConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case course_moduleConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function course_modules_sort(state = {}, action) {
  switch (action.type) {
    case course_moduleConstants.SORT_REQUEST:
      return {
        loading: true
      };
    case course_moduleConstants.SORT_SUCCESS:
      return {
        item: action.sort
      };
    case course_moduleConstants.SORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}