import { appealsConstants } from '../_constants';
import { appealsService } from '../_services';
import { alertActions } from '.';

export const appealsActions = {
  register,
  getById,
  getByYear,
  getAll,
  update,
  delete: _delete,
  import: _import,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    appealsService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: appealsConstants.GET_REQUEST, id } }
  function success(appeal) { return { type: appealsConstants.GET_SUCCESS, appeal } }
  function failure(error) { return { type: appealsConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    appealsService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(appeal) { return { type: appealsConstants.GETALL_REQUEST, appeal } }
  function success(appeals, year) { return { type: appealsConstants.GETALL_SUCCESS, appeals: { appeals: appeals, year: year } } }
  function failure(error) { return { type: appealsConstants.GETALL_FAILURE, error } }
}

function register(appeal) {
  return dispatch => {
    dispatch(request(appeal));

    appealsService.register(appeal)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(appeal) { return { type: appealsConstants.REGISTER_REQUEST, appeal } }
  function success(appeal) { return { type: appealsConstants.REGISTER_SUCCESS, appeal } }
  function failure(error) { return { type: appealsConstants.REGISTER_FAILURE, error } }
}

function update(appeal) {
  return dispatch => {
    dispatch(request(appeal));

    appealsService.update(appeal)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(appeal) { return { type: appealsConstants.REGISTER_REQUEST, appeal } }
  function success(appeal) { return { type: appealsConstants.REGISTER_SUCCESS, appeal } }
  function failure(error) { return { type: appealsConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    appealsService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: appealsConstants.GETALL_REQUEST } }
  function success(appeals) { return { type: appealsConstants.GETALL_SUCCESS, appeals: { appeals: appeals, year: '' } } }
  function failure(error) { return { type: appealsConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    appealsService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: appealsConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: appealsConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: appealsConstants.DELETE_FAILURE, id, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _import() {
  return dispatch => {
    dispatch(request());

    appealsService.import()
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Import successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: appealsConstants.IMPORT_REQUEST } }
  function success(appeals_import) { return { type: appealsConstants.IMPORT_SUCCESS, appeals_import } }
  function failure(error) { return { type: appealsConstants.IMPORT_FAILURE, error } }
}