import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const summaryService = {
  register,
  getAll,
  getById,
  getByYear,
  getYears,
  update,
  delete: _delete,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries/${id}`, requestOptions).then(handleResponse);
}

function getByYear(year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries/year/${year}`, requestOptions).then(handleResponse);
}

function register(summary) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(summary)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries`, requestOptions).then(handleResponse);
}

function getYears() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries/years`, requestOptions).then(handleResponse);
}

function update(summary) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(summary)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries/${summary.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/summaries/${id}`, requestOptions).then(handleResponse);
}
