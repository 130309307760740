import { course_viewConstants } from '../_constants';

export function course_view(state = {}, action) {
  switch (action.type) {
    case course_viewConstants.COURSE_GET_REQUEST:
      return {
        loading: true
      };
    case course_viewConstants.COURSE_GET_SUCCESS:
      return {
        item: action.course.data
      };
    case course_viewConstants.COURSE_GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function modules_view(state = {}, action) {
  switch (action.type) {
    case course_viewConstants.MODULES_GETALL_REQUEST:
      return {
        loading: true
      };
    case course_viewConstants.MODULES_GETALL_SUCCESS:
      return {
        item: action.modules.data
      };
    case course_viewConstants.MODULES_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function module_view(state = {}, action) {
  switch (action.type) {
    case course_viewConstants.MODULE_GET_REQUEST:
      return {
        loading: true
      };
    case course_viewConstants.MODULE_GET_SUCCESS:
      return {
        item: action.module.data
      };
    case course_viewConstants.MODULE_GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function units_view(state = {}, action) {
  switch (action.type) {
    case course_viewConstants.UNITS_GETALL_REQUEST:
      return {
        loading: true
      };
    case course_viewConstants.UNITS_GETALL_SUCCESS:
      return {
        item: action.units.data
      };
    case course_viewConstants.UNITS_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function unit_view(state = {}, action) {
  switch (action.type) {
    case course_viewConstants.UNIT_GET_REQUEST:
      return {
        loading: true
      };
    case course_viewConstants.UNIT_GET_SUCCESS:
      return {
        item: action.unit.data
      };
    case course_viewConstants.UNIT_GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}