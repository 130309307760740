import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const legislationService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  breakContent,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations/${id}`, requestOptions).then(handleResponse);
}

function register(legislation) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(legislation)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations`, requestOptions).then(handleResponse);
}

function update(legislation) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(legislation)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations/${legislation.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations/${id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function breakContent(id, titles, category_id) {
  const requestOptions = {
    method: 'POST',
    cache: 'no-store',
    headers: authHeader(),
    body: JSON.stringify({titles: titles, category_id: category_id})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/legislations/break/${id}`, requestOptions).then(handleResponse);
}