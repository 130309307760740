export const course_viewConstants = {
  COURSE_GET_REQUEST: 'COURSE_REQUEST',
  COURSE_GET_SUCCESS: 'COURSE_SUCCESS',
  COURSE_GET_FAILURE: 'COURSE_FAILURE',

  MODULES_GETALL_REQUEST: 'MODULES_GETALL_REQUEST',
  MODULES_GETALL_SUCCESS: 'MODULES_GETALL_SUCCESS',
  MODULES_GETALL_FAILURE: 'MODULES_GETALL_FAILURE',

  MODULE_GET_REQUEST: 'MODULE_REQUEST',
  MODULE_GET_SUCCESS: 'MODULE_SUCCESS',
  MODULE_GET_FAILURE: 'MODULE_FAILURE',

  UNITS_GETALL_REQUEST: 'UNITS_GETALL_REQUEST',
  UNITS_GETALL_SUCCESS: 'UNITS_GETALL_SUCCESS',
  UNITS_GETALL_FAILURE: 'UNITS_GETALL_FAILURE',

  UNIT_GET_REQUEST: 'UNIT_REQUEST',
  UNIT_GET_SUCCESS: 'UNIT_SUCCESS',
  UNIT_GET_FAILURE: 'UNIT_FAILURE',
};