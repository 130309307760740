import { course_viewConstants } from '../_constants';
import { course_viewService } from '../_services';
import { alertActions } from '.';

export const course_viewActions = {
  getCourse,
  getModules,
  getModule,
  getUnits,
  getUnit
};

function getCourse(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_viewService.getCourse(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_viewConstants.COURSE_GET_REQUEST, id } }
  function success(course) { return { type: course_viewConstants.COURSE_GET_SUCCESS, course } }
  function failure(error) { return { type: course_viewConstants.COURSE_GET_FAILURE, error } }
}

function getModules(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_viewService.getModules(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_viewConstants.MODULES_GETALL_REQUEST, id } }
  function success(modules) { return { type: course_viewConstants.MODULES_GETALL_SUCCESS, modules } }
  function failure(error) { return { type: course_viewConstants.MODULES_GETALL_FAILURE, error } }
}

function getModule(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_viewService.getModule(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_viewConstants.MODULE_GET_REQUEST, id } }
  function success(module) { return { type: course_viewConstants.MODULE_GET_SUCCESS, module } }
  function failure(error) { return { type: course_viewConstants.MODULE_GET_FAILURE, error } }
}

function getUnits(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_viewService.getUnits(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_viewConstants.UNITS_GETALL_REQUEST, id } }
  function success(units) { return { type: course_viewConstants.UNITS_GETALL_SUCCESS, units } }
  function failure(error) { return { type: course_viewConstants.UNITS_GETALL_FAILURE, error } }
}

function getUnit(course, module, unit) {

  return dispatch => {
    dispatch(request());

    course_viewService.getUnit(course, module, unit)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: course_viewConstants.UNIT_GET_REQUEST } }
  function success(unit) { return { type: course_viewConstants.UNIT_GET_SUCCESS, unit } }
  function failure(error) { return { type: course_viewConstants.UNIT_GET_FAILURE, error } }
}
