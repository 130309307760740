import { orderConstants } from '../_constants';
import { orderService } from '../_services';
import { alertActions } from '.';

export const orderActions = {
  register,
  getById,
  getAll,
  getByUser,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    orderService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: orderConstants.GET_REQUEST, id } }
  function success(order) { return { type: orderConstants.GET_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.GET_FAILURE, error } }
}

function register(order) {
  return dispatch => {
    dispatch(request(order));

    orderService.register(order)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Order post successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(order) { return { type: orderConstants.REGISTER_REQUEST, order } }
  function success(order) { return { type: orderConstants.REGISTER_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    orderService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: orderConstants.GETALL_REQUEST } }
  function success(orders) { return { type: orderConstants.GETALL_SUCCESS, orders } }
  function failure(error) { return { type: orderConstants.GETALL_FAILURE, error } }
}

function getByUser($page=1, $user_id=0) {
  return dispatch => {
    dispatch(request());

    orderService.getByUser($page, $user_id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: orderConstants.GETALL_REQUEST } }
  function success(orders) { return { type: orderConstants.GETALL_SUCCESS, orders } }
  function failure(error) { return { type: orderConstants.GETALL_FAILURE, error } }
}

function update(order) {
  return dispatch => {
    dispatch(request(order));

    orderService.updateorder(order)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(order) { return { type: orderConstants.REGISTER_REQUEST, order } }
  function success(order) { return { type: orderConstants.REGISTER_SUCCESS, order } }
  function failure(error) { return { type: orderConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    orderService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: orderConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: orderConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: orderConstants.DELETE_FAILURE, id, error } }
}
