import { productConstants } from '../_constants';

export function products(state = {}, action) {
  switch (action.type) {
    case productConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case productConstants.GETALL_SUCCESS:
      return {
        items: action.product.data
      };
    case productConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function product(state = {}, action) {
  switch (action.type) {
    case productConstants.REGISTER_SUCCESS:
      return {
        item: action.product.data
      };
      /*
    case productConstants.REGISTER_FAILURE:
      return {
        item: action.product.data
      };
      */
    case productConstants.GET_REQUEST:
      return {
        loading: true
      };
    case productConstants.GET_SUCCESS:
      return {
        item: action.product.data
      };
    case productConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case productConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case productConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case productConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function product_download(state = {}, action) {
  switch (action.type) {
    case productConstants.DOWNLOAD_REQUEST:
      return {
        loading: true
      };
    case productConstants.DOWNLOAD_SUCCESS:
      return {
        item: action.product_download
      };
    case productConstants.DOWNLOAD_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function product_check_bought(state = {}, action) {
  switch (action.type) {
    case productConstants.CHECK_BOUGHT_REQUEST:
      return {
        loading: true
      };
    case productConstants.CHECK_BOUGHT_SUCCESS:
      return {
        status: action.bought.data
      };
    case productConstants.CHECK_BOUGHT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function products_bought(state = {}, action) {
  switch (action.type) {
    case productConstants.BOUGHT_REQUEST:
      return {
        loading: true
      };
    case productConstants.BOUGHT_SUCCESS:
      return {
        items: action.products.data
      };
    case productConstants.BOUGHT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function spotlight_sort(state = {}, action) {
  switch (action.type) {
    case productConstants.SPOTLIGHT_SORT_REQUEST:
      return {
        loading: true
      };
    case productConstants.SPOTLIGHT_SORT_SUCCESS:
      return {
        item: action.sort
      };
    case productConstants.SPOTLIGHT_SORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
