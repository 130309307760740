export const briefingsConstants = {
  REGISTER_REQUEST: 'BRIEFINGS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'BRIEFINGS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'BRIEFINGS_REGISTER_FAILURE',

  GET_REQUEST: 'BRIEFINGS_GET_REQUEST',
  GET_SUCCESS: 'BRIEFINGS_GET_SUCCESS',
  GET_FAILURE: 'BRIEFINGS_GET_FAILURE',

  GETALL_REQUEST: 'BRIEFINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'BRIEFINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'BRIEFINGS_GETALL_FAILURE',

  DELETE_REQUEST: 'BRIEFINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'BRIEFINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'BRIEFINGS_DELETE_FAILURE',
};