import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const cartService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  removeProducts,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart/${id}`, requestOptions).then(handleResponse);
}

function register(cart) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(cart)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart`, requestOptions).then(handleResponse);
}

function update(cart) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(cart)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart/${cart.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart/${id}`, requestOptions).then(handleResponse);
}

function removeProducts(id) {
  const requestOptions = {
    method: 'GET',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/cart/remove-products/${id}`, requestOptions).then(handleResponse);
}