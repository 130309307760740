import { taxemailConstants } from '../_constants';

export function taxemails(state = {}, action) {
  switch (action.type) {
    case taxemailConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case taxemailConstants.GETALL_SUCCESS:
      return {
        items: action.taxemails
      };
    case taxemailConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function taxemail(state = {}, action) {
  switch (action.type) {
    case taxemailConstants.REGISTER_SUCCESS:
      return {
        item: action.taxemail.data
      };
    case taxemailConstants.GET_REQUEST:
      return {
        loading: true
      };
    case taxemailConstants.GET_SUCCESS:
      return {
        item: action.taxemail.data
      };
    case taxemailConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case taxemailConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case taxemailConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case taxemailConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}