import { statusConstants } from '../_constants';

export const statusActions = {
  success,
  error,
  clear
};

function success(error) {
  return { type: statusConstants.SUCCESS, error };
}

function error(error) {
  return { type: statusConstants.ERROR, error };
}

function clear() {
  return { type: statusConstants.CLEAR };
}
