import { summaryConstants } from '../_constants';
import { summaryService } from '../_services';
import { alertActions } from '.';

export const summaryActions = {
  register,
  getById,
  getByYear,
  getAll,
  getYears,
  update,
  delete: _delete,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    summaryService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: summaryConstants.GET_REQUEST, id } }
  function success(summary) { return { type: summaryConstants.GET_SUCCESS, summary } }
  function failure(error) { return { type: summaryConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    //dispatch(request({ id }));

    summaryService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(summary) { return { type: summaryConstants.GETALL_REQUEST, summary } }
  function success(summaries, year) { return { type: summaryConstants.GETALL_SUCCESS, summaries: { summaries: summaries, year: year } } }
  function failure(error) { return { type: summaryConstants.GETALL_FAILURE, error } }
}

function register(summary) {
  return dispatch => {
    dispatch(request(summary));

    summaryService.register(summary)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Summary registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(summary) { return { type: summaryConstants.REGISTER_REQUEST, summary } }
  function success(summary) { return { type: summaryConstants.REGISTER_SUCCESS, summary } }
  function failure(error) { return { type: summaryConstants.REGISTER_FAILURE, error } }
}

function update(summary) {
  return dispatch => {
    dispatch(request(summary));

    summaryService.update(summary)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Summary updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(summary) { return { type: summaryConstants.REGISTER_REQUEST, summary } }
  function success(summary) { return { type: summaryConstants.REGISTER_SUCCESS, summary } }
  function failure(error) { return { type: summaryConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    summaryService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: summaryConstants.GETALL_REQUEST } }
  function success(summaries) { return { type: summaryConstants.GETALL_SUCCESS, summaries: { summaries: summaries, year: '' } } }
  function failure(error) { return { type: summaryConstants.GETALL_FAILURE, error } }
}

function getYears() {

  return dispatch => {
    dispatch(request({}));

    summaryService.getYears()
      .then(
        summaries_years => {
          dispatch(success(summaries_years))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(id) { return { type: summaryConstants.GETALL_YEARS_REQUEST, id } }
  function success(summaries_years) { return { type: summaryConstants.GETALL_YEARS_SUCCESS, summaries_years } }
  function failure(error) { return { type: summaryConstants.GETALL_YEARS_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    summaryService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: summaryConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: summaryConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: summaryConstants.DELETE_FAILURE, id, error } }
}