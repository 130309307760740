import { question_categoryConstants } from '../_constants';

export function question_categories(state = {}, action) {
  switch (action.type) {
    case question_categoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case question_categoryConstants.GETALL_SUCCESS:
      return {
        items: action.categories.data
      };
    case question_categoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function question_category(state = {}, action) {
  switch (action.type) {
    case question_categoryConstants.CURRENT_TOPIC:
      return {
        current_topic: action.id
      };
    case question_categoryConstants.REGISTER_SUCCESS:
      return {
        item: action.category.data
      };
    case question_categoryConstants.GET_REQUEST:
      return {
        loading: true
      };
    case question_categoryConstants.GET_SUCCESS:
      return {
        item: action.category.data
      };
    case question_categoryConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case question_categoryConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case question_categoryConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case question_categoryConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}
