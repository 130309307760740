import { formsConstants } from '../_constants';

export function forms(state = {}, action) {
  switch (action.type) {
    case formsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case formsConstants.GETALL_SUCCESS:
      return {
        items: action.forms
      };
    case formsConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function form(state = {}, action) {
  switch (action.type) {
    case formsConstants.REGISTER_SUCCESS:
      return {
        item: action.form.data
      };
    case formsConstants.GET_REQUEST:
      return {
        loading: true
      };
    case formsConstants.GET_SUCCESS:
      return {
        item: action.form.data
      };
    case formsConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case formsConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case formsConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case formsConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}