export const ebriefsConstants = {
  REGISTER_REQUEST: 'EBRIEFS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'EBRIEFS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'EBRIEFS_REGISTER_FAILURE',

  GET_REQUEST: 'EBRIEFS_GET_REQUEST',
  GET_SUCCESS: 'EBRIEFS_GET_SUCCESS',
  GET_FAILURE: 'EBRIEFS_GET_FAILURE',

  GETALL_REQUEST: 'EBRIEFS_GETALL_REQUEST',
  GETALL_SUCCESS: 'EBRIEFS_GETALL_SUCCESS',
  GETALL_FAILURE: 'EBRIEFS_GETALL_FAILURE',

  DELETE_REQUEST: 'EBRIEFS_DELETE_REQUEST',
  DELETE_SUCCESS: 'EBRIEFS_DELETE_SUCCESS',
  DELETE_FAILURE: 'EBRIEFS_DELETE_FAILURE',

  IMPORT_REQUEST: 'EBRIEFS_IMPORT_REQUEST',
  IMPORT_SUCCESS: 'EBRIEFS_IMPORT_SUCCESS',
  IMPORT_FAILURE: 'EBRIEFS_IMPORT_FAILURE',
};