import { fileConstants } from '../_constants';
import { fileService } from '../_services';
import { alertActions } from '.';

export const fileActions = {
  register,
  getById,
  getAll,
  getImage,
  update,
  replaceFile,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    fileService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: fileConstants.GET_REQUEST, id } }
  function success(file) { return { type: fileConstants.GET_SUCCESS, file } }
  function failure(error) { return { type: fileConstants.GET_FAILURE, error } }
}

function register(file) {
  return dispatch => {
    dispatch(request(file));

    fileService.register(file)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(file) { return { type: fileConstants.REGISTER_REQUEST, file } }
  function success(file) { return { type: fileConstants.REGISTER_SUCCESS, file } }
  function failure(error) { return { type: fileConstants.REGISTER_FAILURE, error } }
}

function replaceFile(file, id) {
  return dispatch => {
    dispatch(request(file));

    fileService.replaceFile(file, id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Replaced successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(file) { return { type: fileConstants.REGISTER_REQUEST, file } }
  function success(file) { return { type: fileConstants.REGISTER_SUCCESS, file } }
  function failure(error) { return { type: fileConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    fileService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: fileConstants.GETALL_REQUEST } }
  function success(files) { return { type: fileConstants.GETALL_SUCCESS, files } }
  function failure(error) { return { type: fileConstants.GETALL_FAILURE, error } }
}

function getImage(id) {

  return dispatch => {
    dispatch(request({ id }));

    fileService.getImage(id)
      .then(
        file_image => {
          dispatch(success(file_image))
          dispatch(alertActions.success('Image retrived successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: fileConstants.IMAGE_REQUEST } }
  function success(file_image) { return { type: fileConstants.IMAGE_SUCCESS, file_image: { id: id, file_image: file_image } } }
  function failure(error) { return { type: fileConstants.IMAGE_FAILURE, error } }
}

function update(file) {
  return dispatch => {
    dispatch(request(file));

    fileService.update(file)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(file) { return { type: fileConstants.REGISTER_REQUEST, file } }
  function success(file) { return { type: fileConstants.REGISTER_SUCCESS, file } }
  function failure(error) { return { type: fileConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    fileService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: fileConstants.DELETE_REQUEST, id } }
  function success(file) { return { type: fileConstants.DELETE_SUCCESS, file } }
  function failure(id, error) { return { type: fileConstants.DELETE_FAILURE, id, error } }
}
