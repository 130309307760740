import { summaryConstants } from '../_constants';

export function summaries(state = {}, action) {
  switch (action.type) {
    case summaryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case summaryConstants.GETALL_SUCCESS:
      return {
        items: action.summaries
      };
    case summaryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function summary(state = {}, action) {
  switch (action.type) {
    case summaryConstants.REGISTER_SUCCESS:
      return {
        item: action.summary.data
      };
    case summaryConstants.GET_REQUEST:
      return {
        loading: true
      };
    case summaryConstants.GET_SUCCESS:
      return {
        item: action.summary.data
      };
    case summaryConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case summaryConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case summaryConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case summaryConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}

export function summaries_years(state = {}, action) {
  switch (action.type) {
    case summaryConstants.GETALL_YEARS_REQUEST:
      return {
        loading: true
      };
    case summaryConstants.GETALL_YEARS_SUCCESS:
      return {
        items: action.summaries_years.data
      };
    case summaryConstants.GETALL_YEARS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}