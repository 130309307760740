export const orderConstants = {
  REGISTER_REQUEST: 'ORDER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'ORDER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'ORDER_REGISTER_FAILURE',

  GET_REQUEST: 'ORDER_REQUEST',
  GET_SUCCESS: 'ORDER_SUCCESS',
  GET_FAILURE: 'ORDER_FAILURE',

  GETALL_REQUEST: 'ORDER_GETALL_REQUEST',
  GETALL_SUCCESS: 'ORDER_GETALL_SUCCESS',
  GETALL_FAILURE: 'ORDER_GETALL_FAILURE',

  DELETE_REQUEST: 'ORDER_DELETE_REQUEST',
  DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
  DELETE_FAILURE: 'ORDER_DELETE_FAILURE',

  INPUTS_SEARCH: 'ORDER_INPUTS_SEARCH',
};