import { ebriefsConstants } from '../_constants';
import { ebriefsService } from '../_services';
import { alertActions } from '.';

export const ebriefsActions = {
  register,
  getById,
  getByYear,
  getAll,
  update,
  delete: _delete,
  import: _import,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    ebriefsService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: ebriefsConstants.GET_REQUEST, id } }
  function success(ebrief) { return { type: ebriefsConstants.GET_SUCCESS, ebrief } }
  function failure(error) { return { type: ebriefsConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    //dispatch(request({ id }));

    ebriefsService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(ebriefs) { return { type: ebriefsConstants.GETALL_REQUEST, ebriefs } }
  function success(ebriefs, year) { return { type: ebriefsConstants.GETALL_SUCCESS, ebriefs: { ebriefs: ebriefs, year: year } } }
  function failure(error) { return { type: ebriefsConstants.GETALL_FAILURE, error } }
}

function register(ebrief) {
  return dispatch => {
    dispatch(request(ebrief));

    ebriefsService.register(ebrief)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(ebrief) { return { type: ebriefsConstants.REGISTER_REQUEST, ebrief } }
  function success(ebrief) { return { type: ebriefsConstants.REGISTER_SUCCESS, ebrief } }
  function failure(error) { return { type: ebriefsConstants.REGISTER_FAILURE, error } }
}

function update(ebrief) {
  return dispatch => {
    dispatch(request(ebrief));

    ebriefsService.update(ebrief)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(ebrief) { return { type: ebriefsConstants.REGISTER_REQUEST, ebrief } }
  function success(ebrief) { return { type: ebriefsConstants.REGISTER_SUCCESS, ebrief } }
  function failure(error) { return { type: ebriefsConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    ebriefsService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: ebriefsConstants.GETALL_REQUEST } }
  function success(ebriefs) { return { type: ebriefsConstants.GETALL_SUCCESS, ebriefs: { ebriefs: ebriefs, year: '' } } }
  function failure(error) { return { type: ebriefsConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    ebriefsService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: ebriefsConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: ebriefsConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: ebriefsConstants.DELETE_FAILURE, id, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _import() {
  return dispatch => {
    dispatch(request());

    ebriefsService.import()
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Import successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: ebriefsConstants.IMPORT_REQUEST } }
  function success(ebriefs_import) { return { type: ebriefsConstants.IMPORT_SUCCESS, ebriefs_import } }
  function failure(error) { return { type: ebriefsConstants.IMPORT_FAILURE, error } }
}