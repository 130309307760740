import { cartConstants } from '../_constants';
import { cartService } from '../_services';
import { alertActions } from '.';

export const cartActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete,
  removeProducts,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    cartService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: cartConstants.GET_REQUEST, id } }
  function success(cart) { return { type: cartConstants.GET_SUCCESS, cart } }
  function failure(error) { return { type: cartConstants.GET_FAILURE, error } }
}

function register(cart) {
  return dispatch => {
    dispatch(request(cart));

    cartService.register(cart)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(cart) { return { type: cartConstants.REGISTER_REQUEST, cart } }
  function success(cart) { return { type: cartConstants.REGISTER_SUCCESS, cart } }
  function failure(error) { return { type: cartConstants.REGISTER_FAILURE, error } }
}

function update(cart) {
  return dispatch => {
    dispatch(request(cart));

    cartService.update(cart)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(cart) { return { type: cartConstants.REGISTER_REQUEST, cart } }
  function success(cart) { return { type: cartConstants.REGISTER_SUCCESS, cart } }
  function failure(error) { return { type: cartConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    cartService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: cartConstants.GETALL_REQUEST } }
  function success(carts) { return { type: cartConstants.GETALL_SUCCESS, carts } }
  function failure(error) { return { type: cartConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    cartService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: cartConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: cartConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: cartConstants.DELETE_FAILURE, id, error } }
}


function removeProducts(id) {
  return dispatch => {
    dispatch(request(id));

    cartService.removeProducts(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: cartConstants.REMOVE_REQUEST, id } }
  function success(remove_products) { return { type: cartConstants.REMOVE_SUCCESS, remove_products } }
  function failure(id, error) { return { type: cartConstants.REMOVE_FAILURE, id, error } }
}
