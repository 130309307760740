import { answerConstants } from '../_constants';

export function answers(state = {}, action) {
  switch (action.type) {
    case answerConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case answerConstants.GETALL_SUCCESS:
      return action.answers.data;
    case answerConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function answer(state = {}, action) {
  switch (action.type) {
    case answerConstants.REGISTER_SUCCESS:
      return {
        item: action.answer.data
      };
    case answerConstants.GET_REQUEST:
      return {
        loading: true
      };
    case answerConstants.GET_SUCCESS:
      return {
        item: action.answer.data
      };
    case answerConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case answerConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case answerConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case answerConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}