import { blogConstants } from '../_constants';

export function blogposts(state = {}, action) {
  switch (action.type) {
    case blogConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case blogConstants.GETALL_SUCCESS:
      return {
        items: action.blogposts
      };
    case blogConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function blogpost(state = {}, action) {
  switch (action.type) {
    case blogConstants.REGISTER_SUCCESS:
      return {
        item: action.blogpost.data
      };
    case blogConstants.GET_REQUEST:
      return {
        loading: true
      };
    case blogConstants.GET_SUCCESS:
      return {
        item: action.blogpost.data
      };
    case blogConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case blogConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case blogConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case blogConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function subscribe(state = {}, action) {
  switch (action.type) {
    case blogConstants.SUBSCRIBE_REQUEST:
      return {
        loading: true
      };
      case blogConstants.SUBSCRIBE_SUCCESS:
        return {
          item: action.subscribe.data
        };
    case blogConstants.SUBSCRIBE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}