export const blog_topicConstants = {
  REGISTER_REQUEST: 'BLOG_TOPIC_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'BLOG_TOPIC_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'BLOG_TOPIC_REGISTER_FAILURE',

  GET_REQUEST: 'BLOG_TOPIC_REQUEST',
  GET_SUCCESS: 'BLOG_TOPIC_SUCCESS',
  GET_FAILURE: 'BLOG_TOPIC_FAILURE',

  GETALL_REQUEST: 'BLOG_TOPIC_GETALL_REQUEST',
  GETALL_SUCCESS: 'BLOG_TOPIC_GETALL_SUCCESS',
  GETALL_FAILURE: 'BLOG_TOPIC_GETALL_FAILURE',

  DELETE_REQUEST: 'BLOG_TOPIC_DELETE_REQUEST',
  DELETE_SUCCESS: 'BLOG_TOPIC_DELETE_SUCCESS',
  DELETE_FAILURE: 'BLOG_TOPIC_DELETE_FAILURE',
};