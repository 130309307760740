import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const fileService = {
  register,
  getById,
  getAll,
  getImage,
  update,
  replaceFile,
  delete: _delete
};

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file/${id}`, requestOptions).then(handleResponse);
}

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file${api_params}`, requestOptions).then(handleResponse);
}

function getImage(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file/${id}/content`, requestOptions)
    .then((result) => {
      return result.text();
    });
}

function register(file) {
  let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));
  const requestOptions = {
    method: 'POST',
    //headers: authHeader(),
    headers: { 'Authorization': 'Bearer ' + user.token },
    cache: 'no-store',
    body: file
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file`, requestOptions).then(handleResponse);
}

function replaceFile(file, id) {
  let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));
  const requestOptions = {
    method: 'POST',
    //headers: authHeader(),
    headers: { 'Authorization': 'Bearer ' + user.token },
    cache: 'no-store',
    body: file
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file/${id}/replace`, requestOptions).then(handleResponse);
}

function update(file) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(file)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file/${file.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/file/${id}`, requestOptions).then(handleResponse);
}


