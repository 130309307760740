export const answerConstants = {
  REGISTER_REQUEST: 'ANSWER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'ANSWER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'ANSWER_REGISTER_FAILURE',

  GET_REQUEST: 'ANSWER_REQUEST',
  GET_SUCCESS: 'ANSWER_SUCCESS',
  GET_FAILURE: 'ANSWER_FAILURE',

  GETALL_REQUEST: 'ANSWER_GETALL_REQUEST',
  GETALL_SUCCESS: 'ANSWER_GETALL_SUCCESS',
  GETALL_FAILURE: 'ANSWER_GETALL_FAILURE',

  DELETE_REQUEST: 'ANSWER_DELETE_REQUEST',
  DELETE_SUCCESS: 'ANSWER_DELETE_SUCCESS',
  DELETE_FAILURE: 'ANSWER_DELETE_FAILURE',

  INPUTS_SEARCH: 'ANSWER_INPUTS_SEARCH',
};