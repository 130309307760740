import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const addressService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/address`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/address/${id}`, requestOptions).then(handleResponse);
}

function register(address) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(address)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/address`, requestOptions).then(handleResponse);
}

function update(address) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(address)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/address/${address.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${id}`, requestOptions).then(handleResponse);
}
