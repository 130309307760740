import { manualConstants } from '../_constants';

export function manuals(state = {}, action) {
  switch (action.type) {
    case manualConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case manualConstants.GETALL_SUCCESS:
      return {
        items: action.manuals.data
      };
    case manualConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function manual(state = {}, action) {
  switch (action.type) {
    case manualConstants.REGISTER_SUCCESS:
      return {
        item: action.manual.data
      };
    case manualConstants.GET_REQUEST:
      return {
        loading: true
      };
    case manualConstants.GET_SUCCESS:
      return {
        item: action.manual.data
      };
    case manualConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case manualConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case manualConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case manualConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function manual_topic(state = {}, action) {
  switch (action.type) {
    case manualConstants.GET_SUB_REQUEST:
      return {
        loading: true
      };
    case manualConstants.GET_SUB_SUCCESS:
      return {
        item: action.manual_topic.data
      };
    case manualConstants.GET_SUB_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
