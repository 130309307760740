export const categoryConstants = {
  REGISTER_REQUEST: 'CATEGORY_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'CATEGORY_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'CATEGORY_REGISTER_FAILURE',

  GET_REQUEST: 'CATEGORY_GET_REQUEST',
  GET_SUCCESS: 'CATEGORY_GET_SUCCESS',
  GET_FAILURE: 'CATEGORY_GET_FAILURE',

  GETALL_REQUEST: 'CATEGORY_GETALL_REQUEST',
  GETALL_SUCCESS: 'CATEGORY_GETALL_SUCCESS',
  GETALL_FAILURE: 'CATEGORY_GETALL_FAILURE',

  DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
  DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',

  CURRENT_TOPIC: 'CURRENT_TOPIC',

  GET_ID_REQUEST: 'CATEGORY_ID_GET_REQUEST',
  GET_ID_SUCCESS: 'CATEGORY_ID_GET_SUCCESS',
  GET_ID_FAILURE: 'CATEGORY_ID_GET_FAILURE',

  SORT_REQUEST: 'CATEGORY_SORT_REQUEST',
  SORT_SUCCESS: 'CATEGORY_SORT_SUCCESS',
  SORT_FAILURE: 'CATEGORY_SORT_FAILURE',
};