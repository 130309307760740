import {handleResponsePublic as handleResponse} from '../_helpers';

export const searchService = {
  search,
  searchAdvanced,
};

function search(term) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify({
      "term": term
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/search/`, requestOptions).then(handleResponse);
}

function searchAdvanced(model, term) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify({
      "term": term,
      "section": model
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/advanced-search/`, requestOptions).then(handleResponse);
}


