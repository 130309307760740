import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const course_moduleService = {
  register,
  getAll,
  getById,
  update,
  updateSort,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules/${id}`, requestOptions).then(handleResponse);
}

function register(course_module) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(course_module),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules`, requestOptions).then(handleResponse);
}

function update(course_module) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(course_module),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules/${course_module.id}`, requestOptions).then(handleResponse);
}

function updateSort(items) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify({ items: items })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules/sort`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/courses/modules/${id}`, requestOptions).then(handleResponse);
}

