export const summaryConstants = {
  REGISTER_REQUEST: 'SUMMARY_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'SUMMARY_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'SUMMARY_REGISTER_FAILURE',

  GET_REQUEST: 'SUMMARY_GET_REQUEST',
  GET_SUCCESS: 'SUMMARY_GET_SUCCESS',
  GET_FAILURE: 'SUMMARY_GET_FAILURE',

  GETALL_REQUEST: 'SUMMARY_GETALL_REQUEST',
  GETALL_SUCCESS: 'SUMMARY_GETALL_SUCCESS',
  GETALL_FAILURE: 'SUMMARY_GETALL_FAILURE',

  DELETE_REQUEST: 'SUMMARY_DELETE_REQUEST',
  DELETE_SUCCESS: 'SUMMARY_DELETE_SUCCESS',
  DELETE_FAILURE: 'SUMMARY_DELETE_FAILURE',

  GETALL_YEARS_REQUEST: 'SUMMARY_YEARS_GETALL_REQUEST',
  GETALL_YEARS_SUCCESS: 'SUMMARY_YEARS_GETALL_SUCCESS',
  GETALL_YEARS_FAILURE: 'SUMMARY_YEARS_GETALL_FAILURE',
};