import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { validateToken } from '../_actions';

class PrivateRouteCMS extends Component {

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    this.checkAcces();
  }

  checkAcces = () => {
    if (this.props.auth.user) {
      this.props.validateToken(this.props.auth.user.token)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { user, validToken } = this.props.auth;
    return (
      <Route
        {...rest}
        render={props => {
          return (user && validToken) ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/cms/login', state: { from: props.location } }} />
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({ auth: state.authentication });
const mapDispatchToProps = dispatch => bindActionCreators({ validateToken }, dispatch);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PrivateRouteCMS);
