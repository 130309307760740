export const fileConstants = {
  REGISTER_REQUEST: 'FILES_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'FILES_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'FILES_REGISTER_FAILURE',

  GET_REQUEST: 'FILES_GET_REQUEST',
  GET_SUCCESS: 'FILES_GET_SUCCESS',
  GET_FAILURE: 'FILES_GET_FAILURE',

  GETALL_REQUEST: 'FILES_GETALL_REQUEST',
  GETALL_SUCCESS: 'FILES_GETALL_SUCCESS',
  GETALL_FAILURE: 'FILES_GETALL_FAILURE',

  DELETE_REQUEST: 'FILES_DELETE_REQUEST',
  DELETE_SUCCESS: 'FILES_DELETE_SUCCESS',
  DELETE_FAILURE: 'FILES_DELETE_FAILURE',

  IMAGE_REQUEST: 'FILES_IMAGE_REQUEST',
  IMAGE_SUCCESS: 'FILES_IMAGE_SUCCESS',
  IMAGE_FAILURE: 'FILES_IMAGE_FAILURE',
};