import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const productService = {
  register,
  getAll,
  getById,
  update,
  updateDownload,
  removeDownload,
  getDownload,
  checkBought,
  updateSort,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/${id}`, requestOptions).then(handleResponse);
}

function register(product) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(product),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products`, requestOptions).then(handleResponse);
}

function update(product) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(product),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/${product.id}`, requestOptions).then(handleResponse);
}

function updateDownload(file, id) {
  let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));
  const requestOptions = {
    method: 'POST',
    //headers: authHeader(),
    headers: { 'Authorization': 'Bearer ' + user.token },
    cache: 'no-store',
    body: file
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/update_download/${id}`, requestOptions).then(handleResponse);
}

function removeDownload(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/remove_download/${id}`, requestOptions).then(handleResponse);
}

function getDownload(product, download_file_name) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/${product}/download`, requestOptions)
  .then(response => response.blob())
  .then(function(ebookBlob) {
    var objectURL = URL.createObjectURL(ebookBlob);
    let a = document.createElement('a');
    a.href = objectURL;
    a.download = download_file_name;
    a.click();
    return true;
  });
}

function checkBought(product) {
  const requestOptions = {
    method: 'GET',
    cache: 'no-store',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/${product}/bought`, requestOptions).then(handleResponse);
}

function updateSort(items) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify({ items: items })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/spotlight/sort`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/products/${id}`, requestOptions).then(handleResponse);
}

