import { userConstants } from '../_constants';

const INITIAL_STATE = {
  error: null,
  token: null,
  item: null
}

export function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


  export function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.GET_PROFILE_REQUEST:
      return {
        loading: true
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        item: action.user.data.user[0]
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.UPDATE_PROFILE_REQUEST:
      return {
        loading: true
      };
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        item: action.user.data.user[0]
      };
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case userConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case userConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        loading: true
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        items: action.register.data
      };
    case userConstants.REGISTER_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function activate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.ACTIVATE_REQUEST:
      return {
        loading: true
      };
    case userConstants.ACTIVATE_SUCCESS:
      return {
        items: action.activate.data
      };
    case userConstants.ACTIVATE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function user_reset(state = {}, action) {
  switch (action.type) {
    case userConstants.RESET_REQUEST_REQUEST:
    case userConstants.RESET_VERIFY_REQUEST:
    case userConstants.RESET_CHANGE_REQUEST:
      return {
        loading: true
      };
    case userConstants.RESET_REQUEST_SUCCESS:
    case userConstants.RESET_VERIFY_SUCCESS:
    case userConstants.RESET_CHANGE_SUCCESS:
      return {
        item: action.reset.data
      };
    case userConstants.RESET_REQUEST_FAILURE:
    case userConstants.RESET_VERIFY_FAILURE:
    case userConstants.RESET_CHANGE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.ROLES_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.ROLES_GETALL_SUCCESS:
      return {
        items: action.roles
      };
    case userConstants.ROLES_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}



export function authors(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.AUTHORS_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.AUTHORS_GETALL_SUCCESS:
      return {
        items: action.authors.data
      };
    case userConstants.AUTHORS_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_addresses(state = {}, action) {
  switch (action.type) {
    case userConstants.ADDRESSES_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.ADDRESSES_GETALL_SUCCESS:
      return {
        items: action.my_addresses.data
      };
    case userConstants.ADDRESSES_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_purchases(state = {}, action) {
  switch (action.type) {
    case userConstants.PURCHASES_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.PURCHASES_GETALL_SUCCESS:
      return {
        items: action.my_purchases.data
      };
    case userConstants.PURCHASES_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_courses(state = {}, action) {
  switch (action.type) {
    case userConstants.COURSES_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.COURSES_GETALL_SUCCESS:
      return {
        items: action.my_courses.data
      };
    case userConstants.COURSES_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_books(state = {}, action) {
  switch (action.type) {
    case userConstants.BOOKS_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.BOOKS_GETALL_SUCCESS:
      return {
        items: action.my_books.data
      };
    case userConstants.BOOKS_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_questions(state = {}, action) {
  switch (action.type) {
    case userConstants.QUESTIONS_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.QUESTIONS_GETALL_SUCCESS:
      return {
        items: action.my_questions.data
      };
    case userConstants.QUESTIONS_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_answers(state = {}, action) {
  switch (action.type) {
    case userConstants.ANSWERS_GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.ANSWERS_GETALL_SUCCESS:
      return {
        items: action.my_answers.data
      };
    case userConstants.ANSWERS_GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function my_cart(state = {}, action) {
  switch (action.type) {
    case userConstants.SHOPPING_CART_GET_REQUEST:
      return {
        loading: true
      };
    case userConstants.SHOPPING_CART_GET_SUCCESS:
      return {
        item: action.my_cart.data
      };
    case userConstants.SHOPPING_CART_GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function message(state = {}, action) {
  switch (action.type) {
    case userConstants.MESSAGE_REQUEST:
      return {
        loading: true
      };
    case userConstants.MESSAGE_SUCCESS:
      return {
        item: action.message.data
      };
    case userConstants.MESSAGE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
