export const taxemailConstants = {
  REGISTER_REQUEST: 'TAXEMAIL_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'TAXEMAIL_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'TAXEMAIL_REGISTER_FAILURE',

  GET_REQUEST: 'TAXEMAIL_REQUEST',
  GET_SUCCESS: 'TAXEMAIL_SUCCESS',
  GET_FAILURE: 'TAXEMAIL_FAILURE',

  GETALL_REQUEST: 'TAXEMAIL_GETALL_REQUEST',
  GETALL_SUCCESS: 'TAXEMAIL_GETALL_SUCCESS',
  GETALL_FAILURE: 'TAXEMAIL_GETALL_FAILURE',

  DELETE_REQUEST: 'TAXEMAIL_DELETE_REQUEST',
  DELETE_SUCCESS: 'TAXEMAIL_DELETE_SUCCESS',
  DELETE_FAILURE: 'TAXEMAIL_DELETE_FAILURE',

  DOWNLOAD_REQUEST: 'TAXEMAIL_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'TAXEMAIL_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'TAXEMAIL_DOWNLOAD_FAILURE',

  TEMPLATE_REQUEST: 'TAXEMAIL_TEMPLATE_REQUEST',
  TEMPLATE_SUCCESS: 'TAXEMAIL_TEMPLATE_SUCCESS',
  TEMPLATE_FAILURE: 'TAXEMAIL_TEMPLATE_FAILURE',
};