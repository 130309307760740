import { course_unitConstants } from '../_constants';
import { course_unitService } from '../_services';
import { alertActions } from '.';

export const course_unitActions = {
  register,
  getById,
  getAll,
  update,
  updateSort,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_unitService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_unitConstants.GET_REQUEST, id } }
  function success(course_unit) { return { type: course_unitConstants.GET_SUCCESS, course_unit } }
  function failure(error) { return { type: course_unitConstants.GET_FAILURE, error } }
}

function register(course_unit) {
  return dispatch => {
    dispatch(request(course_unit));

    course_unitService.register(course_unit)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course_unit) { return { type: course_unitConstants.REGISTER_REQUEST, course_unit } }
  function success(course_unit) { return { type: course_unitConstants.REGISTER_SUCCESS, course_unit } }
  function failure(error) { return { type: course_unitConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    course_unitService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: course_unitConstants.GETALL_REQUEST } }
  function success(course_units) { return { type: course_unitConstants.GETALL_SUCCESS, course_units } }
  function failure(error) { return { type: course_unitConstants.GETALL_FAILURE, error } }
}

function update(course_unit) {
  return dispatch => {
    dispatch(request(course_unit));

    course_unitService.update(course_unit)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course_unit) { return { type: course_unitConstants.REGISTER_REQUEST, course_unit } }
  function success(course_unit) { return { type: course_unitConstants.REGISTER_SUCCESS, course_unit } }
  function failure(error) { return { type: course_unitConstants.REGISTER_FAILURE, error } }
}

function updateSort(course_units) {
  return dispatch => {
    dispatch(request(course_units));

    course_unitService.updateSort(course_units)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated sort successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(sort) { return { type: course_unitConstants.SORT_REQUEST, sort } }
  function success(sort) { return { type: course_unitConstants.SORT_SUCCESS, sort } }
  function failure(error) { return { type: course_unitConstants.SORT_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    course_unitService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: course_unitConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: course_unitConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: course_unitConstants.DELETE_FAILURE, id, error } }
}
