import { formsConstants } from '../_constants';
import { formsService } from '../_services';
import { alertActions } from '.';

export const formsActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete,
  import: _import,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    formsService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: formsConstants.GET_REQUEST, id } }
  function success(form) { return { type: formsConstants.GET_SUCCESS, form } }
  function failure(error) { return { type: formsConstants.GET_FAILURE, error } }
}

function register(form) {
  return dispatch => {
    dispatch(request(form));

    formsService.register(form)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(form) { return { type: formsConstants.REGISTER_REQUEST, form } }
  function success(form) { return { type: formsConstants.REGISTER_SUCCESS, form } }
  function failure(error) { return { type: formsConstants.REGISTER_FAILURE, error } }
}

function update(form) {
  return dispatch => {
    dispatch(request(form));

    formsService.update(form)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(form) { return { type: formsConstants.REGISTER_REQUEST, form } }
  function success(form) { return { type: formsConstants.REGISTER_SUCCESS, form } }
  function failure(error) { return { type: formsConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    formsService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: formsConstants.GETALL_REQUEST } }
  function success(forms) { return { type: formsConstants.GETALL_SUCCESS, forms: { forms: forms, year: '' } } }
  function failure(error) { return { type: formsConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    formsService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: formsConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: formsConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: formsConstants.DELETE_FAILURE, id, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _import() {
  return dispatch => {
    dispatch(request());

    formsService.import()
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Import successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: formsConstants.IMPORT_REQUEST } }
  function success(forms_import) { return { type: formsConstants.IMPORT_SUCCESS, forms_import } }
  function failure(error) { return { type: formsConstants.IMPORT_FAILURE, error } }
}