import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const course_viewService = {
  getCourse,
  getModules,
  getModule,
  getUnits,
  getUnit
};

function getCourse(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/course/${id}`, requestOptions).then(handleResponse);
}

function getModules(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/course/${id}/modules`, requestOptions).then(handleResponse);
}

function getModule(id, module) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/course/${id}/${module}`, requestOptions).then(handleResponse);
}

function getUnits(id, module) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/course/${id}/${module}/units`, requestOptions).then(handleResponse);
}

function getUnit(course, module, unit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/course/${course}/${module}/${unit}`, requestOptions).then(handleResponse);
}

