export const eventConstants = {
  REGISTER_REQUEST: 'EVENT_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'EVENT_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'EVENT_REGISTER_FAILURE',

  GET_REQUEST: 'EVENT_GET_REQUEST',
  GET_SUCCESS: 'EVENT_GET_SUCCESS',
  GET_FAILURE: 'EVENT_GET_FAILURE',

  GETALL_REQUEST: 'EVENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'EVENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'EVENT_GETALL_FAILURE',

  DELETE_REQUEST: 'EVENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'EVENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'EVENT_DELETE_FAILURE',
};