import axios from 'axios';
import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const userService = {
  isValidToken,
  loginCMS,
  login,
  logout,
  register,
  activate,
  resetRequest,
  resetVerify,
  resetChange,
  getAll,
  getById,
  update,
  getProfile,
  getRoles,
  getAuthors,
  delete: _delete,
  contactForm,
  interestedUnlimited,
  myAddresses,
  myPurchases,
  myCourses,
  myBooks,
  myQuestions,
  myAnswers,
  myCart,
};

async function isValidToken(token) {

  const options = {
    url: `${process.env.REACT_APP_API_URL}/validate-token`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  };

  try {
    const response = await axios(options);
    //console.log(response.status);
    return response.status;
  }
  catch (error) {
    //console.log('inside catch block.');
    if (error.response) {
      //console.log(error.response.data);
      //console.log(error.response.status);
      return error.response.status;
      //console.log(error.response.headers);
    }
    //else if (error.request) {
    //  console.log(error.request);
    //  return error.request;
    //}
    //else {
    //  console.log('Error', error.message);
    //}
  }

}

async function loginCMS(username, password) {

  const options = {
    url: `${process.env.REACT_APP_API_URL}/login`,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({email:username, password:password})
  };

  const response = await axios(options);
  let user = response.data["user_details"];
  let token = response.data["access_token"];

  // set axios default header
  //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  //console.log(axios.defaults.headers.common.Authorization);

  user["token"] = token;
  user["cms"] = "taxworld";
  user["headers"] = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };
  localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`, JSON.stringify(user));
  localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}questions_search`, JSON.stringify({ page: 1, string: '', status: '', author: 0 }));
  return user;

}

async function login(username, password) {

  const options = {
    url: `${process.env.REACT_APP_API_URL}/login`,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({email:username, password:password})
  };

  const response = await axios(options);
  let user = response.data["user_details"];
  let token = response.data["access_token"];

  // set axios default header
  //axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
  //console.log(axios.defaults.headers.common.Authorization);

  user["token"] = token;
  user["cms"] = "";
  user["headers"] = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };
  localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`, JSON.stringify(user));
  //console.log(user)
  return user;

}

/*
// Start: Get user details
// -----------------------
function userInfo(bearer_token) {

    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + bearer_token }
    };

    return fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions)
      .then((result) => {
        return result.json();
      })
      .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        console.log(user)
        return user;
      });

}
// ---------------------
// End: Get user details
 */

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`);
  localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}access_token`);
  localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}token`);

  localStorage.setItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}questions_search`, JSON.stringify({page: 1, string: '', status: '', author: 0}));

  localStorage.clear();
}

function getProfile(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${id}/edit`, requestOptions).then(handleResponse);
}

function getRoles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/roles`, requestOptions).then(handleResponse);
}

function getAuthors() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/?roles=authors`, requestOptions).then(handleResponse);
}

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/register`, requestOptions).then(handleResponse);
}

function activate(token) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/activate/${token}`, requestOptions).then(handleResponse);
}

function resetRequest(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify({email: email})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/password/create`, requestOptions).then(handleResponse);
}

function resetVerify(token) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/password/reset/${token}`, requestOptions).then(handleResponse);
}

function resetChange(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/password/reset`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, requestOptions).then(handleResponse);
}

function contactForm(message) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify(message)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/message/contact_form`, requestOptions).then(handleResponse);
}

function interestedUnlimited(message) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    cache: 'no-store',
    body: JSON.stringify(message)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/message/unlimited`, requestOptions).then(handleResponse);
}

function myAddresses() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-addresses`, requestOptions).then(handleResponse);
}

function myPurchases() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-purchases`, requestOptions).then(handleResponse);
}

function myCourses() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-courses`, requestOptions).then(handleResponse);
}

function myBooks() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-ebooks`, requestOptions).then(handleResponse);
}

function myQuestions(page) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-questions/${page}`, requestOptions).then(handleResponse);
}

function myAnswers(page) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-answers/${page}`, requestOptions).then(handleResponse);
}

function myCart() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/my-cart`, requestOptions).then(handleResponse);
}
