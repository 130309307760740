import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const questionService = {
  postQuestion,
  postAnswer,
  getAll,
  getByUser,
  getById,
  updateQuestion,
  updateAnswer,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions${api_params}`, requestOptions).then(handleResponse);
}

function getByUser($page, $user_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions/?1=1&page=${$page}&user_id=${$user_id}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions/${id}`, requestOptions).then(handleResponse);
}

function postQuestion(question) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(question)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions`, requestOptions).then(handleResponse);
}

function postAnswer(answer) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(answer)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/answers`, requestOptions).then(handleResponse);
}

function updateQuestion(question) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(question)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions/${question.id}`, requestOptions).then(handleResponse);
}

function updateAnswer(answer) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(answer)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/answers/${answer.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/questions/${id}`, requestOptions).then(handleResponse);
}


