import { ebriefsConstants } from '../_constants';

export function ebriefs(state = {}, action) {
  switch (action.type) {
    case ebriefsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case ebriefsConstants.GETALL_SUCCESS:
      return {
        items: action.ebriefs
      };
    case ebriefsConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function ebrief(state = {}, action) {
  switch (action.type) {
    case ebriefsConstants.REGISTER_SUCCESS:
      return {
        item: action.ebrief.data
      };
    case ebriefsConstants.GET_REQUEST:
      return {
        loading: true
      };
    case ebriefsConstants.GET_SUCCESS:
      return {
        item: action.ebrief.data
      };
    case ebriefsConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case ebriefsConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case ebriefsConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case ebriefsConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function ebriefs_import(state = {}, action) {
  switch (action.type) {
    case ebriefsConstants.IMPORT_REQUEST:
      return {
        loading: true
      };
    case ebriefsConstants.IMPORT_SUCCESS:
      return {
        items: action.ebriefs_import
      };
    case ebriefsConstants.IMPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
