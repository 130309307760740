import { searchConstants } from '../_constants';

export function search_term(state = {}, action) {
  switch (action.type) {
    case searchConstants.SEARCH_TERM:
      return {
        item: action.term
      };
    default:
      return state
  }
}

export function search(state = {}, action) {
  switch (action.type) {
    case searchConstants.GET_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GET_SUCCESS:
      return {
        items: action.search
      };
    case searchConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function search_advanced(state = {}, action) {
  switch (action.type) {
    case searchConstants.GET_ADVANCED_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GET_ADVANCED_SUCCESS:
      return {
        items: action.search
      };
    case searchConstants.GET_ADVANCED_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}