export const appealsConstants = {
  REGISTER_REQUEST: 'APPEALS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'APPEALS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'APPEALS_REGISTER_FAILURE',

  GET_REQUEST: 'APPEALS_GET_REQUEST',
  GET_SUCCESS: 'APPEALS_GET_SUCCESS',
  GET_FAILURE: 'APPEALS_GET_FAILURE',

  GETALL_REQUEST: 'APPEALS_GETALL_REQUEST',
  GETALL_SUCCESS: 'APPEALS_GETALL_SUCCESS',
  GETALL_FAILURE: 'APPEALS_GETALL_FAILURE',

  DELETE_REQUEST: 'APPEALS_DELETE_REQUEST',
  DELETE_SUCCESS: 'APPEALS_DELETE_SUCCESS',
  DELETE_FAILURE: 'APPEALS_DELETE_FAILURE',

  IMPORT_REQUEST: 'APPEALS_IMPORT_REQUEST',
  IMPORT_SUCCESS: 'APPEALS_IMPORT_SUCCESS',
  IMPORT_FAILURE: 'APPEALS_IMPORT_FAILURE',
};