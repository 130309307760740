import { productConstants } from '../_constants';
import { productService } from '../_services';
import { alertActions } from '.';
import { statusActions } from './';

export const productActions = {
  register,
  getById,
  getAll,
  update,
  updateDownload,
  removeDownload,
  getDownload,
  checkBought,
  updateSort,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    productService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: productConstants.GET_REQUEST, id } }
  function success(product) { return { type: productConstants.GET_SUCCESS, product } }
  function failure(error) { return { type: productConstants.GET_FAILURE, error } }
}

function register(product) {
  return dispatch => {
    dispatch(request(product));

    productService.register(product)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(product) { return { type: productConstants.REGISTER_REQUEST, product } }
  function success(product) { return { type: productConstants.REGISTER_SUCCESS, product } }
  function failure(error) { return { type: productConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    productService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: productConstants.GETALL_REQUEST } }
  function success(product) { return { type: productConstants.GETALL_SUCCESS, product } }
  function failure(error) { return { type: productConstants.GETALL_FAILURE, error } }
}

function update(product) {
  return dispatch => {
    dispatch(request(product));

    productService.update(product)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated product successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(product) { return { type: productConstants.REGISTER_REQUEST, product } }
  function success(product) { return { type: productConstants.REGISTER_SUCCESS, product } }
  function failure(error) { return { type: productConstants.REGISTER_FAILURE, error } }
}

function updateDownload(file, id) {
  return dispatch => {
    dispatch(request(file));

    productService.updateDownload(file, id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated file successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(product_download) { return { type: productConstants.DOWNLOAD_REQUEST, product_download } }
  function success(product_download) { return { type: productConstants.DOWNLOAD_SUCCESS, product_download } }
  function failure(error) { return { type: productConstants.DOWNLOAD_FAILURE, error } }
}

function removeDownload(id) {
  return dispatch => {
    dispatch(request());

    productService.removeDownload(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Removed file successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(product_download) { return { type: productConstants.DOWNLOAD_REQUEST, product_download } }
  function success(product_download) { return { type: productConstants.DOWNLOAD_SUCCESS, product_download } }
  function failure(error) { return { type: productConstants.DOWNLOAD_FAILURE, error } }
}

function getDownload(id, download_file_name) {
  return dispatch => {
    dispatch(request(id));

    productService.getDownload(id, download_file_name)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Download successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(product_download) { return { type: productConstants.DOWNLOAD_REQUEST, product_download } }
  function success(product_download) { return { type: productConstants.DOWNLOAD_SUCCESS, product_download } }
  function failure(error) { return { type: productConstants.DOWNLOAD_FAILURE, error } }
}

function checkBought(id) {
  return dispatch => {
    dispatch(request(id));

    productService.checkBought(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(statusActions.success('Check successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(statusActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: productConstants.CHECK_BOUGHT_REQUEST, id } }
  function success(bought) { return { type: productConstants.CHECK_BOUGHT_SUCCESS, bought } }
  function failure(error) { return { type: productConstants.CHECK_BOUGHT_FAILURE, error } }
}

function updateSort(products) {
  return dispatch => {
    dispatch(request(products));

    productService.updateSort(products)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated sort successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(sort) { return { type: productConstants.SPOTLIGHT_SORT_REQUEST, sort } }
  function success(sort) { return { type: productConstants.SPOTLIGHT_SORT_SUCCESS, sort } }
  function failure(error) { return { type: productConstants.SPOTLIGHT_SORT_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    productService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: productConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: productConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: productConstants.DELETE_FAILURE, id, error } }
}
