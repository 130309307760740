export const course_moduleConstants = {
  REGISTER_REQUEST: 'COURSE_MODULE_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'COURSE_MODULE_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'COURSE_MODULE_REGISTER_FAILURE',

  GET_REQUEST: 'COURSE_MODULE_REQUEST',
  GET_SUCCESS: 'COURSE_MODULE_SUCCESS',
  GET_FAILURE: 'COURSE_MODULE_FAILURE',

  GETALL_REQUEST: 'COURSE_MODULE_GETALL_REQUEST',
  GETALL_SUCCESS: 'COURSE_MODULE_GETALL_SUCCESS',
  GETALL_FAILURE: 'COURSE_MODULE_GETALL_FAILURE',

  DELETE_REQUEST: 'COURSE_MODULE_DELETE_REQUEST',
  DELETE_SUCCESS: 'COURSE_MODULE_DELETE_SUCCESS',
  DELETE_FAILURE: 'COURSE_MODULE_DELETE_FAILURE',

  SORT_REQUEST: 'COURSE_MODULE_SORT_REQUEST',
  SORT_SUCCESS: 'COURSE_MODULE_SORT_SUCCESS',
  SORT_FAILURE: 'COURSE_MODULE_SORT_FAILURE',
};