export const cartConstants = {
  REGISTER_REQUEST: 'CART_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'CART_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'CART_REGISTER_FAILURE',

  GET_REQUEST: 'CART_GET_REQUEST',
  GET_SUCCESS: 'CART_GET_SUCCESS',
  GET_FAILURE: 'CART_GET_FAILURE',

  GETALL_REQUEST: 'CART_GETALL_REQUEST',
  GETALL_SUCCESS: 'CART_GETALL_SUCCESS',
  GETALL_FAILURE: 'CART_GETALL_FAILURE',

  DELETE_REQUEST: 'CART_DELETE_REQUEST',
  DELETE_SUCCESS: 'CART_DELETE_SUCCESS',
  DELETE_FAILURE: 'CART_DELETE_FAILURE',

  REMOVE_REQUEST: 'REMOVE_PRODUCTS_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_PRODUCTS_SUCCESS',
  REMOVE_FAILURE: 'REMOVE_PRODUCTS_FAILURE',
};