import { courseConstants } from '../_constants';

export function courses(state = {}, action) {
  switch (action.type) {
    case courseConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case courseConstants.GETALL_SUCCESS:
      return {
        items: action.courses.data
      };
    case courseConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function course(state = {}, action) {
  switch (action.type) {
    case courseConstants.REGISTER_SUCCESS:
      return {
        item: action.course.data
      };
    case courseConstants.GET_REQUEST:
      return {
        loading: true
      };
    case courseConstants.GET_SUCCESS:
      return {
        item: action.course.data
      };
    case courseConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case courseConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case courseConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case courseConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function course_enroll(state = {}, action) {
  switch (action.type) {
    case courseConstants.ENROLL_REQUEST:
      return {
        loading: true
      };
    case courseConstants.ENROLL_SUCCESS:
      return {
        item: action.course_enroll
      };
    case courseConstants.ENROLL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}