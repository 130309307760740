import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`));

const initialState = user ? {
  loggedIn: true,
  user,
  validToken: true,
  headers: user.headers,
  currentUrl: null
} : {
  loggingIn: false,
  user: null,
  validToken: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': ''
  },
  cache: 'no-store',
  currentUrl: null
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      if(state.currentUrl) {
        console.log(state.currentUrl)
      }
      return {
        ...state,
        loggingIn: true,
        user: action.user,
        validToken: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + action.user.token
        },
        cache: 'no-store'
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        validToken: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + action.user.token
        },
        cache: 'no-store'
      };
    case 'TOKEN_VALIDATED':
      if (action.payload) {
        return { ...state, validToken: true }
      } else {
        localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`);
        return { ...state, validToken: false, user: null }
      }
    case 'TOKEN_UNAUTHORISED':
      localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}user`);
      // return { ...state, validToken: false, user: null, currentUrl: null }
      return initialState;
    case userConstants.LOGIN_FAILURE:
      return initialState;
    case userConstants.LOGOUT:
      return initialState;
    default:
      return state
  }
}
