export const blog_commentConstants = {
  REGISTER_REQUEST: 'BLOG_COMMENT_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'BLOG_COMMENT_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'BLOG_COMMENT_REGISTER_FAILURE',

  GET_REQUEST: 'BLOG_COMMENT_REQUEST',
  GET_SUCCESS: 'BLOG_COMMENT_SUCCESS',
  GET_FAILURE: 'BLOG_COMMENT_FAILURE',

  GETALL_REQUEST: 'BLOG_COMMENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'BLOG_COMMENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'BLOG_COMMENT_GETALL_FAILURE',

  DELETE_REQUEST: 'BLOG_COMMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'BLOG_COMMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'BLOG_COMMENT_DELETE_FAILURE',
};