import { eventConstants } from '../_constants';
import { eventService } from '../_services';
import { alertActions } from '.';

export const eventActions = {
  register,
  getById,
  getByYear,
  getAll,
  update,
  delete: _delete,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    eventService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: eventConstants.GET_REQUEST, id } }
  function success(event) { return { type: eventConstants.GET_SUCCESS, event } }
  function failure(error) { return { type: eventConstants.GET_FAILURE, error } }
}

function getByYear(year) {

  return dispatch => {
    //dispatch(request({ id }));

    eventService.getByYear(year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(event) { return { type: eventConstants.GETALL_REQUEST, event } }
  function success(event, year) { return { type: eventConstants.GETALL_SUCCESS, event: { event: event, year: year } } }
  function failure(error) { return { type: eventConstants.GETALL_FAILURE, error } }
}

function register(event) {
  return dispatch => {
    dispatch(request(event));

    eventService.register(event)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(event) { return { type: eventConstants.REGISTER_REQUEST, event } }
  function success(event) { return { type: eventConstants.REGISTER_SUCCESS, event } }
  function failure(error) { return { type: eventConstants.REGISTER_FAILURE, error } }
}

function update(event) {
  return dispatch => {
    dispatch(request(event));

    eventService.update(event)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(event) { return { type: eventConstants.REGISTER_REQUEST, event } }
  function success(event) { return { type: eventConstants.REGISTER_SUCCESS, event } }
  function failure(error) { return { type: eventConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    eventService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: eventConstants.GETALL_REQUEST } }
  function success(events) { return { type: eventConstants.GETALL_SUCCESS, events } }
  function failure(error) { return { type: eventConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    eventService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: eventConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: eventConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: eventConstants.DELETE_FAILURE, id, error } }
}