export const addressConstants = {
  REGISTER_REQUEST: 'ADDRESS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'ADDRESS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'ADDRESS_REGISTER_FAILURE',

  GET_REQUEST: 'ADDRESS_REQUEST',
  GET_SUCCESS: 'ADDRESS_SUCCESS',
  GET_FAILURE: 'ADDRESS_FAILURE',

  GETALL_REQUEST: 'ADDRESS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ADDRESS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ADDRESS_GETALL_FAILURE',

  DELETE_REQUEST: 'ADDRESS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ADDRESS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ADDRESS_DELETE_FAILURE',

  INPUTS_SEARCH: 'ADDRESS_INPUTS_SEARCH',
};