export const letterConstants = {
  REGISTER_REQUEST: 'LETTER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'LETTER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'LETTER_REGISTER_FAILURE',

  GET_REQUEST: 'LETTER_REQUEST',
  GET_SUCCESS: 'LETTER_SUCCESS',
  GET_FAILURE: 'LETTER_FAILURE',

  GETALL_REQUEST: 'LETTER_GETALL_REQUEST',
  GETALL_SUCCESS: 'LETTER_GETALL_SUCCESS',
  GETALL_FAILURE: 'LETTER_GETALL_FAILURE',

  DELETE_REQUEST: 'LETTER_DELETE_REQUEST',
  DELETE_SUCCESS: 'LETTER_DELETE_SUCCESS',
  DELETE_FAILURE: 'LETTER_DELETE_FAILURE',

  DOWNLOAD_REQUEST: 'LETTER_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'LETTER_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'LETTER_DOWNLOAD_FAILURE',
};