import { blog_topicConstants } from '../_constants';

export function blogtopics(state = {}, action) {
  switch (action.type) {
    case blog_topicConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case blog_topicConstants.GETALL_SUCCESS:
      return {
        items: action.blogtopics.data
      };
    case blog_topicConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case blog_topicConstants.DELETE_REQUEST:
      // add 'deleting:true' property to post being deleted
      return {
        ...state,
        items: state.items.map(post =>
          post.id === action.id
            ? { ...post, deleting: true }
            : post
        )
      };
    case blog_topicConstants.DELETE_SUCCESS:
      // remove deleted post from state
      return {
        items: state.items.filter(post => post.id !== action.id)
      };
    case blog_topicConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to post
      return {
        ...state,
        items: state.items.map(post => {
          if (post.id === action.id) {
            // make copy of post without 'deleting:true' property
            const { deleting, ...postCopy } = post;
            // return copy of post with 'deleteError:[error]' property
            return { ...postCopy, deleteError: action.error };
          }

          return post;
        })
      };
    default:
      return state
  }
}


export function blogtopic(state = {}, action) {
  switch (action.type) {
    case blog_topicConstants.REGISTER_SUCCESS:
      return {
        item: action.blogtopic.data
      };
    case blog_topicConstants.GET_REQUEST:
      return {
        loading: true
      };
    case blog_topicConstants.GET_SUCCESS:
      return {
        item: action.blogtopic.data
      };
    case blog_topicConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}