import { checklistConstants } from '../_constants';

export function checklists(state = {}, action) {
  switch (action.type) {
    case checklistConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case checklistConstants.GETALL_SUCCESS:
      return {
        items: action.checklists
      };
    case checklistConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function checklist(state = {}, action) {
  switch (action.type) {
    case checklistConstants.REGISTER_SUCCESS:
      return {
        item: action.checklist.data
      };
    case checklistConstants.GET_REQUEST:
      return {
        loading: true
      };
    case checklistConstants.GET_SUCCESS:
      return {
        item: action.checklist.data
      };
    case checklistConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case checklistConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case checklistConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case checklistConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}