import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const formsService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  import: _import,
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms/${id}`, requestOptions).then(handleResponse);
}

function register(appeal) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(appeal)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms`, requestOptions).then(handleResponse);
}

function update(appeal) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(appeal)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms/${appeal.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms/${id}`, requestOptions).then(handleResponse);
}

function _import() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/forms_import`, requestOptions).then(handleResponse);
}


