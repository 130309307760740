import { searchConstants } from '../_constants';
import { searchService } from '../_services';
//import { alertActions } from '.';

export const searchActions = {
  search,
  searchAdvanced,
};

function search(term) {
  return dispatch => {
    dispatch(request());

    searchService.search(term)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: searchConstants.GET_REQUEST } }
  function success(result) { return { type: searchConstants.GET_SUCCESS, search: { result: result.data, term: term } } }
  function failure(error) { return { type: searchConstants.GET_FAILURE, error } }
}

function searchAdvanced(model, term) {
  return dispatch => {
    dispatch(request());

    searchService.searchAdvanced(model, term)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: searchConstants.GET_ADVANCED_REQUEST } }
  function success(result) { return { type: searchConstants.GET_ADVANCED_SUCCESS, search: { result: result.data, term: term, model: model } } }
  function failure(error) { return { type: searchConstants.GET_ADVANCED_FAILURE, error } }
}