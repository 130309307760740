import { appealsConstants } from '../_constants';

export function appeals(state = {}, action) {
  switch (action.type) {
    case appealsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case appealsConstants.GETALL_SUCCESS:
      return {
        items: action.appeals
      };
    case appealsConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function appeal(state = {}, action) {
  switch (action.type) {
    case appealsConstants.REGISTER_SUCCESS:
      return {
        item: action.appeal.data
      };
    case appealsConstants.GET_REQUEST:
      return {
        loading: true
      };
    case appealsConstants.GET_SUCCESS:
      return {
        item: action.appeal.data
      };
    case appealsConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case appealsConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case appealsConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case appealsConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function appeals_import(state = {}, action) {
  switch (action.type) {
    case appealsConstants.IMPORT_REQUEST:
      return {
        loading: true
      };
    case appealsConstants.IMPORT_SUCCESS:
      return {
        items: action.appeals_import
      };
    case appealsConstants.IMPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
