import { eventConstants } from '../_constants';

export function events(state = {}, action) {
  switch (action.type) {
    case eventConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case eventConstants.GETALL_SUCCESS:
      return {
        items: action.events
      };
    case eventConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function event(state = {}, action) {
  switch (action.type) {
    case eventConstants.REGISTER_SUCCESS:
      return {
        item: action.event.data
      };
    case eventConstants.GET_REQUEST:
      return {
        loading: true
      };
    case eventConstants.GET_SUCCESS:
      return {
        item: action.event.data
      };
    case eventConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case eventConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case eventConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case eventConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}