import { course_categoryConstants } from '../_constants';

export function course_categories(state = {}, action) {
  switch (action.type) {
    case course_categoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case course_categoryConstants.GETALL_SUCCESS:
      return {
        items: action.categories.data
      };
    case course_categoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function course_category(state = {}, action) {
  switch (action.type) {
    case course_categoryConstants.CURRENT_TOPIC:
      return {
        current_topic: action.id
      };
    case course_categoryConstants.REGISTER_SUCCESS:
      return {
        item: action.category.data
      };
    case course_categoryConstants.GET_REQUEST:
      return {
        loading: true
      };
    case course_categoryConstants.GET_SUCCESS:
      return {
        item: action.category.data
      };
    case course_categoryConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case course_categoryConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case course_categoryConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case course_categoryConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}
