export const formsConstants = {
  REGISTER_REQUEST: 'FORMS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'FORMS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'FORMS_REGISTER_FAILURE',

  GET_REQUEST: 'FORMS_GET_REQUEST',
  GET_SUCCESS: 'FORMS_GET_SUCCESS',
  GET_FAILURE: 'FORMS_GET_FAILURE',

  GETALL_REQUEST: 'FORMS_GETALL_REQUEST',
  GETALL_SUCCESS: 'FORMS_GETALL_SUCCESS',
  GETALL_FAILURE: 'FORMS_GETALL_FAILURE',

  DELETE_REQUEST: 'FORMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'FORMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'FORMS_DELETE_FAILURE',
};