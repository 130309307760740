export const course_unitConstants = {
  REGISTER_REQUEST: 'COURSE_UNIT_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'COURSE_UNIT_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'COURSE_UNIT_REGISTER_FAILURE',

  GET_REQUEST: 'COURSE_UNIT_REQUEST',
  GET_SUCCESS: 'COURSE_UNIT_SUCCESS',
  GET_FAILURE: 'COURSE_UNIT_FAILURE',

  GETALL_REQUEST: 'COURSE_UNIT_GETALL_REQUEST',
  GETALL_SUCCESS: 'COURSE_UNIT_GETALL_SUCCESS',
  GETALL_FAILURE: 'COURSE_UNIT_GETALL_FAILURE',

  DELETE_REQUEST: 'COURSE_UNIT_DELETE_REQUEST',
  DELETE_SUCCESS: 'COURSE_UNIT_DELETE_SUCCESS',
  DELETE_FAILURE: 'COURSE_UNIT_DELETE_FAILURE',

  SORT_REQUEST: 'COURSE_UNIT_SORT_REQUEST',
  SORT_SUCCESS: 'COURSE_UNIT_SORT_SUCCESS',
  SORT_FAILURE: 'COURSE_UNIT_SORT_FAILURE',
};