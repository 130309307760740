export const manualConstants = {
  REGISTER_REQUEST: 'MANUALS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'MANUALS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'MANUALS_REGISTER_FAILURE',

  GET_REQUEST: 'MANUALS_GET_REQUEST',
  GET_SUCCESS: 'MANUALS_GET_SUCCESS',
  GET_FAILURE: 'MANUALS_GET_FAILURE',

  GET_SUB_REQUEST: 'MANUALS_GET_SUB_REQUEST',
  GET_SUB_SUCCESS: 'MANUALS_GET_SUB_SUCCESS',
  GET_SUB_FAILURE: 'MANUALS_GET_SUB_FAILURE',

  GETALL_REQUEST: 'MANUALS_GETALL_REQUEST',
  GETALL_SUCCESS: 'MANUALS_GETALL_SUCCESS',
  GETALL_FAILURE: 'MANUALS_GETALL_FAILURE',

  DELETE_REQUEST: 'MANUALS_DELETE_REQUEST',
  DELETE_SUCCESS: 'MANUALS_DELETE_SUCCESS',
  DELETE_FAILURE: 'MANUALS_DELETE_FAILURE',
};