export const legislationConstants = {
  REGISTER_REQUEST: 'LEGISLATION_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'LEGISLATION_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'LEGISLATION_REGISTER_FAILURE',

  GET_REQUEST: 'LEGISLATION_GET_REQUEST',
  GET_SUCCESS: 'LEGISLATION_GET_SUCCESS',
  GET_FAILURE: 'LEGISLATION_GET_FAILURE',

  GETALL_REQUEST: 'LEGISLATION_GETALL_REQUEST',
  GETALL_SUCCESS: 'LEGISLATION_GETALL_SUCCESS',
  GETALL_FAILURE: 'LEGISLATION_GETALL_FAILURE',

  DELETE_REQUEST: 'LEGISLATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'LEGISLATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'LEGISLATION_DELETE_FAILURE',

  BREAK_REQUEST: 'LEGISLATION_BREAK_REQUEST',
  BREAK_SUCCESS: 'LEGISLATION_BREAK_SUCCESS',
  BREAK_FAILURE: 'LEGISLATION_BREAK_FAILURE',
};