import { guidanceConstants } from '../_constants';


export function guidances(state = {}, action) {
  switch (action.type) {
    case guidanceConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case guidanceConstants.GETALL_SUCCESS:
      return {
        items: action.guidances.data
      };
    case guidanceConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case guidanceConstants.DELETE_REQUEST:
      // add 'deleting:true' property to topic being deleted
      return {
        ...state,
        items: state.items.map(topic =>
          topic.id === action.id
            ? { ...topic, deleting: true }
            : topic
        )
      };
    default:
      return state
  }
}


export function guidance(state = {}, action) {
  switch (action.type) {
    case guidanceConstants.REGISTER_SUCCESS:
      return {
        item: action.guidance.data
      };
    case guidanceConstants.GET_REQUEST:
      return {
        loading: true
      };
    case guidanceConstants.GET_SUCCESS:
      return {
        item: action.guidance.data
      };
    case guidanceConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case guidanceConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case guidanceConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case guidanceConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}