import { questionConstants } from '../_constants';

const initialState = {page: 1, topic: 0, string: "", status: "", author: 0};

export function questions(state = {}, action) {
  switch (action.type) {
    case questionConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case questionConstants.GETALL_SUCCESS:
      return {
        items: action.questions.data
      };
    case questionConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function question(state = {}, action) {
  switch (action.type) {
    case questionConstants.REGISTER_SUCCESS:
      return {
        item: action.question.data
      };
    case questionConstants.GET_REQUEST:
      return {
        loading: true
      };
    case questionConstants.GET_SUCCESS:
      return {
        item: action.question.data
      };
    case questionConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case questionConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case questionConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case questionConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function questions_search(state = initialState, action) {
  switch (action.type) {
    case questionConstants.INPUTS_SEARCH:
        return {
          page: action.query.page,
          string: action.query.string,
          status: action.query.status,
          author: action.query.author
        };
    default:
      return state
  }
}