import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const categoryService = {
  register,
  getAll,
  getPlainList,
  getId,
  getById,
  getByParentId,
  update,
  updateSort,
  updateSortNested,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories${api_params}`, requestOptions).then(handleResponse);
}

function getPlainList(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories/plain/list${api_params}`, requestOptions).then(handleResponse);
}

function getId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories/id/${id}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories/${id}`, requestOptions).then(handleResponse);
}

function getByParentId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories/parent/${id}`, requestOptions).then(handleResponse);
}

function register(category) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories`, requestOptions).then(handleResponse);
}

function update(category) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(category)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/categories/${category.id}`, requestOptions).then(handleResponse);
}

function updateSort(category, type) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify({ items: category.items })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/${type}/sort/${category.id}`, requestOptions).then(handleResponse);
}

function updateSortNested(category, type) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify({ items: category.items })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/${type}/sort_nested/${category.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${id}`, requestOptions).then(handleResponse);
}


