export const blogConstants = {
  REGISTER_REQUEST: 'BLOG_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'BLOG_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'BLOG_REGISTER_FAILURE',

  GET_REQUEST: 'BLOG_REQUEST',
  GET_SUCCESS: 'BLOG_SUCCESS',
  GET_FAILURE: 'BLOG_FAILURE',

  GETALL_REQUEST: 'BLOG_GETALL_REQUEST',
  GETALL_SUCCESS: 'BLOG_GETALL_SUCCESS',
  GETALL_FAILURE: 'BLOG_GETALL_FAILURE',

  DELETE_REQUEST: 'BLOG_DELETE_REQUEST',
  DELETE_SUCCESS: 'BLOG_DELETE_SUCCESS',
  DELETE_FAILURE: 'BLOG_DELETE_FAILURE',

  SUBSCRIBE_REQUEST: 'BLOG_SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS: 'BLOG_SUBSCRIBE_SUCCESS',
  SUBSCRIBE_FAILURE: 'BLOG_SUBSCRIBE_FAILURE',
};