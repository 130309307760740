import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const guidanceService = {
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/guidances${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/guidances/${id}`, requestOptions).then(handleResponse);
}

function register(guidance) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(guidance)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/guidances`, requestOptions).then(handleResponse);
}

function update(guidance) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(guidance)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/guidances/${guidance.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/guidances/${id}`, requestOptions).then(handleResponse);
}


