export const searchConstants = {
  GET_REQUEST: 'SEARCH_REQUEST',
  GET_SUCCESS: 'SEARCH_SUCCESS',
  GET_FAILURE: 'SEARCH_FAILURE',

  GET_ADVANCED_REQUEST: 'SEARCH_ADVANCED_REQUEST',
  GET_ADVANCED_SUCCESS: 'SEARCH_ADVANCED_SUCCESS',
  GET_ADVANCED_FAILURE: 'SEARCH_ADVANCED_FAILURE',

  SEARCH_TERM: 'SEARCH_TERM',
};