export const product_categoryConstants = {
  REGISTER_REQUEST: 'PRODUCTS_CATEGORY_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'PRODUCTS_CATEGORY_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'PRODUCTS_CATEGORY_REGISTER_FAILURE',

  GET_REQUEST: 'PRODUCTS_CATEGORY_REQUEST',
  GET_SUCCESS: 'PRODUCTS_CATEGORY_SUCCESS',
  GET_FAILURE: 'PRODUCTS_CATEGORY_FAILURE',

  GETALL_REQUEST: 'PRODUCTS_CATEGORY_GETALL_REQUEST',
  GETALL_SUCCESS: 'PRODUCTS_CATEGORY_GETALL_SUCCESS',
  GETALL_FAILURE: 'PRODUCTS_CATEGORY_GETALL_FAILURE',

  DELETE_REQUEST: 'PRODUCTS_CATEGORY_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRODUCTS_CATEGORY_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRODUCTS_CATEGORY_DELETE_FAILURE',
};