import { product_categoryConstants } from '../_constants';
import { product_categoryService } from '../_services';
import { alertActions } from '.';

export const product_categoryActions = {
  register,
  getById,
  getByParentId,
  getAll,
  getPlainList,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    product_categoryService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: product_categoryConstants.GET_REQUEST, id } }
  function success(category) { return { type: product_categoryConstants.GET_SUCCESS, category } }
  function failure(error) { return { type: product_categoryConstants.GET_FAILURE, error } }
}

function getByParentId(id) {

  return dispatch => {
    //dispatch(request({ category }));

    product_categoryService.getByParentId(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(category) { return { type: product_categoryConstants.GET_REQUEST, category } }
  function success(category) { return { type: product_categoryConstants.GET_SUCCESS, category } }
  function failure(error) { return { type: product_categoryConstants.GET_FAILURE, error } }
}

function register(category) {
  return dispatch => {
    dispatch(request(category));

    product_categoryService.register(category)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(category) { return { type: product_categoryConstants.REGISTER_REQUEST, category } }
  function success(category) { return { type: product_categoryConstants.REGISTER_SUCCESS, category } }
  function failure(error) { return { type: product_categoryConstants.REGISTER_FAILURE, error } }
}

function update(category) {
  return dispatch => {
    dispatch(request(category));

    product_categoryService.update(category)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(category) { return { type: product_categoryConstants.REGISTER_REQUEST, category } }
  function success(category) { return { type: product_categoryConstants.REGISTER_SUCCESS, category } }
  function failure(error) { return { type: product_categoryConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    product_categoryService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: product_categoryConstants.GETALL_REQUEST } }
  function success(categories) { return { type: product_categoryConstants.GETALL_SUCCESS, categories } }
  function failure(error) { return { type: product_categoryConstants.GETALL_FAILURE, error } }
}

function getPlainList(api_params) {
  return dispatch => {
    dispatch(request());

    product_categoryService.getPlainList(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: product_categoryConstants.GETALL_REQUEST } }
  function success(categories) { return { type: product_categoryConstants.GETALL_SUCCESS, categories } }
  function failure(error) { return { type: product_categoryConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    product_categoryService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: product_categoryConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: product_categoryConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: product_categoryConstants.DELETE_FAILURE, id, error } }
}
