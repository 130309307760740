export const courseConstants = {
  REGISTER_REQUEST: 'COURSE_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'COURSE_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'COURSE_REGISTER_FAILURE',

  GET_REQUEST: 'COURSE_REQUEST',
  GET_SUCCESS: 'COURSE_SUCCESS',
  GET_FAILURE: 'COURSE_FAILURE',

  GETALL_REQUEST: 'COURSE_GETALL_REQUEST',
  GETALL_SUCCESS: 'COURSE_GETALL_SUCCESS',
  GETALL_FAILURE: 'COURSE_GETALL_FAILURE',

  DELETE_REQUEST: 'COURSE_DELETE_REQUEST',
  DELETE_SUCCESS: 'COURSE_DELETE_SUCCESS',
  DELETE_FAILURE: 'COURSE_DELETE_FAILURE',

  ENROLL_REQUEST: 'ENROLL_REQUEST',
  ENROLL_SUCCESS: 'ENROLL_SUCCESS',
  ENROLL_FAILURE: 'ENROLL_FAILURE',
  
  UNIT_REQUEST: 'UNIT_REQUEST',
  UNIT_SUCCESS: 'UNIT_SUCCESS',
  UNIT_FAILURE: 'UNIT_FAILURE',
};