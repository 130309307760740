import { letterConstants } from '../_constants';

export function letters(state = {}, action) {
  switch (action.type) {
    case letterConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case letterConstants.GETALL_SUCCESS:
      return {
        items: action.letters
      };
    case letterConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function letter(state = {}, action) {
  switch (action.type) {
    case letterConstants.REGISTER_SUCCESS:
      return {
        item: action.letter.data
      };
    case letterConstants.GET_REQUEST:
      return {
        loading: true
      };
    case letterConstants.GET_SUCCESS:
      return {
        item: action.letter.data
      };
    case letterConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case letterConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case letterConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case letterConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function letter_template(state = {}, action) {
  switch (action.type) {
    case letterConstants.TEMPLATE_REQUEST:
      return {
        loading: true
      };
    case letterConstants.TEMPLATE_SUCCESS:
      return {
        item: action.product_download
      };
    case letterConstants.TEMPLATE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}