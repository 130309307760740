import { cartConstants } from '../_constants';

export function carts(state = {}, action) {
  switch (action.type) {
    case cartConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case cartConstants.GETALL_SUCCESS:
      return {
        items: action.carts
      };
    case cartConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function cart(state = {}, action) {
  switch (action.type) {
    case cartConstants.REGISTER_SUCCESS:
      return {
        item: action.cart.data
      };
    case cartConstants.GET_REQUEST:
      return {
        loading: true
      };
    case cartConstants.GET_SUCCESS:
      return {
        item: action.cart.data
      };
    case cartConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case cartConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case cartConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case cartConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function remove_products(state = {}, action) {
  switch (action.type) {
    case cartConstants.REMOVE_REQUEST:
      return {
        loading: true
      };
    case cartConstants.REMOVE_SUCCESS:
      return {
        items: action.remove_products
      };
    case cartConstants.REMOVE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
