import { guidanceConstants } from '../_constants';
import { guidanceService } from '../_services';
import { alertActions } from '.';

export const guidanceActions = {
  register,
  getById,
  getAll,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    guidanceService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: guidanceConstants.GET_REQUEST, id } }
  function success(guidance) { return { type: guidanceConstants.GET_SUCCESS, guidance } }
  function failure(error) { return { type: guidanceConstants.GET_FAILURE, error } }
}

function register(guidance) {
  return dispatch => {
    dispatch(request(guidance));

    guidanceService.register(guidance)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(guidance) { return { type: guidanceConstants.REGISTER_REQUEST, guidance } }
  function success(guidance) { return { type: guidanceConstants.REGISTER_SUCCESS, guidance } }
  function failure(error) { return { type: guidanceConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    guidanceService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: guidanceConstants.GETALL_REQUEST } }
  function success(guidances) { return { type: guidanceConstants.GETALL_SUCCESS, guidances } }
  function failure(error) { return { type: guidanceConstants.GETALL_FAILURE, error } }
}

function update(guidance) {
  return dispatch => {
    dispatch(request(guidance));

    guidanceService.update(guidance)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(guidance) { return { type: guidanceConstants.REGISTER_REQUEST, guidance } }
  function success(guidance) { return { type: guidanceConstants.REGISTER_SUCCESS, guidance } }
  function failure(error) { return { type: guidanceConstants.REGISTER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    guidanceService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: guidanceConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: guidanceConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: guidanceConstants.DELETE_FAILURE, id, error } }
}