import { course_moduleConstants } from '../_constants';
import { course_moduleService } from '../_services';
import { alertActions } from '.';

export const course_moduleActions = {
  register,
  getById,
  getAll,
  update,
  updateSort,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    course_moduleService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: course_moduleConstants.GET_REQUEST, id } }
  function success(course_module) { return { type: course_moduleConstants.GET_SUCCESS, course_module } }
  function failure(error) { return { type: course_moduleConstants.GET_FAILURE, error } }
}

function register(course_module) {
  return dispatch => {
    dispatch(request(course_module));

    course_moduleService.register(course_module)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Module registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course_module) { return { type: course_moduleConstants.REGISTER_REQUEST, course_module } }
  function success(course_module) { return { type: course_moduleConstants.REGISTER_SUCCESS, course_module } }
  function failure(error) { return { type: course_moduleConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    course_moduleService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: course_moduleConstants.GETALL_REQUEST } }
  function success(course_modules) { return { type: course_moduleConstants.GETALL_SUCCESS, course_modules } }
  function failure(error) { return { type: course_moduleConstants.GETALL_FAILURE, error } }
}

function update(course_module) {
  return dispatch => {
    dispatch(request(course_module));

    course_moduleService.update(course_module)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Module updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(course_module) { return { type: course_moduleConstants.REGISTER_REQUEST, course_module } }
  function success(course_module) { return { type: course_moduleConstants.REGISTER_SUCCESS, course_module } }
  function failure(error) { return { type: course_moduleConstants.REGISTER_FAILURE, error } }
}

function updateSort(course_modules) {
  return dispatch => {
    dispatch(request(course_modules));

    course_moduleService.updateSort(course_modules)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated sort successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(sort) { return { type: course_moduleConstants.SORT_REQUEST, sort } }
  function success(sort) { return { type: course_moduleConstants.SORT_SUCCESS, sort } }
  function failure(error) { return { type: course_moduleConstants.SORT_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    course_moduleService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: course_moduleConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: course_moduleConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: course_moduleConstants.DELETE_FAILURE, id, error } }
}
