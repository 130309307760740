export const questionConstants = {
  REGISTER_REQUEST: 'QUESTION_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'QUESTION_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'QUESTION_REGISTER_FAILURE',

  GET_REQUEST: 'QUESTION_REQUEST',
  GET_SUCCESS: 'QUESTION_SUCCESS',
  GET_FAILURE: 'QUESTION_FAILURE',

  GETALL_REQUEST: 'QUESTION_GETALL_REQUEST',
  GETALL_SUCCESS: 'QUESTION_GETALL_SUCCESS',
  GETALL_FAILURE: 'QUESTION_GETALL_FAILURE',

  DELETE_REQUEST: 'QUESTION_DELETE_REQUEST',
  DELETE_SUCCESS: 'QUESTION_DELETE_SUCCESS',
  DELETE_FAILURE: 'QUESTION_DELETE_FAILURE',

  INPUTS_SEARCH: 'QUESTION_INPUTS_SEARCH',
};