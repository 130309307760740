import {authHeader, handleResponsePublic as handleResponse} from '../_helpers';

export const question_categoryService = {
  register,
  getAll,
  getPlainList,
  getById,
  getByParentId,
  update,
  delete: _delete
};

function getAll(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category${api_params}`, requestOptions).then(handleResponse);
}

function getPlainList(api_params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category/plain/list${api_params}`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category/${id}`, requestOptions).then(handleResponse);
}

function getByParentId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    cache: 'no-store'
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category/parent/${id}`, requestOptions).then(handleResponse);
}

function register(question_category) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(question_category)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category`, requestOptions).then(handleResponse);
}

function update(question_category) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    cache: 'no-store',
    body: JSON.stringify(question_category)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/question-category/${question_category.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    cache: 'no-store',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/topic/${id}`, requestOptions).then(handleResponse);
}


