import { legislationConstants } from '../_constants';


export function legislations(state = {}, action) {
  switch (action.type) {
    case legislationConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case legislationConstants.GETALL_SUCCESS:
      return {
        items: action.legislations.data
      };
    case legislationConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function legislation(state = {}, action) {
  switch (action.type) {
    case legislationConstants.REGISTER_SUCCESS:
      return {
        item: action.legislation.data
      };
    case legislationConstants.GET_REQUEST:
      return {
        loading: true
      };
    case legislationConstants.GET_SUCCESS:
      return {
        item: action.legislation.data
      };
    case legislationConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case legislationConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case legislationConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case legislationConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}


export function break_content(state = {}, action) {
  switch (action.type) {
    case legislationConstants.BREAK_REQUEST:
      return {
        loading: true
      };
    case legislationConstants.BREAK_SUCCESS:
      return {
        items: action.break_content.data.request
      };
    case legislationConstants.BREAK_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}