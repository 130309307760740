import { fileConstants } from '../_constants';

export function files(state = {}, action) {
  switch (action.type) {
    case fileConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case fileConstants.GETALL_SUCCESS:
      return {
        items: action.files.data
      };
    case fileConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function file(state = {}, action) {
  switch (action.type) {
    case fileConstants.REGISTER_SUCCESS:
      return {
        item: action.file.data
      };
    case fileConstants.GET_REQUEST:
      return {
        loading: true
      };
    case fileConstants.GET_SUCCESS:
      return {
        item: action.file.data
      };
    case fileConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case fileConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case fileConstants.DELETE_SUCCESS:
      return {
        item: action.file.data
      };
    case fileConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


const initialState = {
  item: []
}
export function file_image(state = initialState, action) {
  switch (action.type) {
    case fileConstants.IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fileConstants.IMAGE_SUCCESS:
      let exists = state.item.findIndex(obj => obj.id === parseInt(action.file_image.id));
      // make a copy of the existing array
      let chosenIds = state.item.slice();

      if(exists !== -1) {
        chosenIds = chosenIds.filter(obj => obj.id  !== parseInt(action.file_image.id));
      }
      // modify the COPY, not the original
      chosenIds.push(action.file_image);
      return {
        ...state,
        //item: [...state.item, action.file_image]
        item: chosenIds
      };
    case fileConstants.IMAGE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}
