import { addressConstants } from '../_constants';
import { addressService } from '../_services';
import { alertActions } from '.';

export const addressActions = {
  register,
  getById,
  getAll,
  getByUser,
  update,
  delete: _delete
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    addressService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: addressConstants.GET_REQUEST, id } }
  function success(address) { return { type: addressConstants.GET_SUCCESS, address } }
  function failure(error) { return { type: addressConstants.GET_FAILURE, error } }
}

function register(address) {
  return dispatch => {
    dispatch(request(address));

    addressService.register(address)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Address post successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(address) { return { type: addressConstants.REGISTER_REQUEST, address } }
  function success(address) { return { type: addressConstants.REGISTER_SUCCESS, address } }
  function failure(error) { return { type: addressConstants.REGISTER_FAILURE, error } }
}

function update(address) {
  return dispatch => {
    dispatch(request(address));

    addressService.update(address)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Address update successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(address) { return { type: addressConstants.REGISTER_REQUEST, address } }
  function success(address) { return { type: addressConstants.REGISTER_SUCCESS, address } }
  function failure(error) { return { type: addressConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    addressService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: addressConstants.GETALL_REQUEST } }
  function success(addresses) { return { type: addressConstants.GETALL_SUCCESS, addresses } }
  function failure(error) { return { type: addressConstants.GETALL_FAILURE, error } }
}

function getByUser($page=1, $user_id=0) {
  return dispatch => {
    dispatch(request());

    addressService.getByUser($page, $user_id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: addressConstants.GETALL_REQUEST } }
  function success(addresses) { return { type: addressConstants.GETALL_SUCCESS, addresses } }
  function failure(error) { return { type: addressConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    addressService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(id));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: addressConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: addressConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: addressConstants.DELETE_FAILURE, id, error } }
}
