import { addressConstants } from '../_constants';

export function addresses(state = {}, action) {
  switch (action.type) {
    case addressConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case addressConstants.GETALL_SUCCESS:
      return {
        items: action.addresses.data
      };
    case addressConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}


export function address(state = {}, action) {
  switch (action.type) {
    case addressConstants.REGISTER_SUCCESS:
      return {
        item: action.address.data
      };
    case addressConstants.GET_REQUEST:
      return {
        loading: true
      };
    case addressConstants.GET_SUCCESS:
      return {
        item: action.address.data
      };
    case addressConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case addressConstants.DELETE_REQUEST:
      return {
        loading: true
      };
    case addressConstants.DELETE_SUCCESS:
      return {
        loading: true
      };
    case addressConstants.DELETE_FAILURE:
      return {
        loading: true
      };
    default:
      return state
  }
}